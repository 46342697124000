/* eslint-disable react-hooks/rules-of-hooks */
import { useGroups } from '@modules/groups/state';
import { ChartOptions, ScriptableContext } from 'chart.js';
import { useGroupAnalyticsSelector } from '../state';

export const getGroupsGraphOptions = (): ChartOptions<'bar'> => ({
  responsive: true,
  interaction: {
    mode: 'nearest',
    axis: 'x',
    intersect: false,
  },
  plugins: {
    tooltip: {
      displayColors: false,
      backgroundColor: '#2A343C',
      titleColor: '#D0DDE7',
      bodyColor: '#D0DDE7',
      titleFont: { family: 'GTPressura', size: 11, weight: '400' },
      bodyFont: { family: 'GTPressura', size: 11, weight: '400' },
      enabled: true,
    },
    legend: {
      display: false,
    },
  },
  elements: {
    line: {
      tension: 0.3,
    },
    point: {
      radius: 0,
    },
  },
  scales: {
    x: {
      grid: {
        color: 'transparent',
      },
      beginAtZero: true,
      ticks: {
        color: '#61645E',
        font: {
          size: 14,
          weight: '300',
          family: 'HK Grotesk',
          lineHeight: 1.25,
        },
      },
    },
    y: {
      stacked: true,
      grid: {
        color: '#61645E',
      },
      beginAtZero: true,
      min: 0,
      border: {
        display: false,
      },
      ticks: {
        callback: (value: number) => {
          if (Number.isInteger(value)) {
            return value.toString();
          }
        },
        font: {
          size: 14,
          weight: '300',
          lineHeight: 1.25,
          family: 'HK Grotesk',
        },
      },
    },
  },
});

interface MonthlyMembersCount {
  [month: string]: number;
}

/**@markoboras0712 internationalize this */
function getMonthCount(month: string) {
  if (month === '01') return 'Sij';
  if (month === '02') return 'Velj';
  if (month === '03') return 'Ožu';
  if (month === '04') return 'Tra';
  if (month === '05') return 'Svi';
  if (month === '06') return 'Lip';
  if (month === '07') return 'Srp';
  if (month === '08') return 'Kol';
  if (month === '09') return 'Ruj';
  if (month === '10') return 'Lis';
  if (month === '11') return 'Stu';
  if (month === '12') return 'Pro';
}

function getMonthlyMembersCount(
  orderedTest: GroupMembersDataPoint[],
): MonthlyMembersCount {
  const monthlyCounts: MonthlyMembersCount = {
    Sij: 0,
    Velj: 0,
    Ožu: 0,
    Tra: 0,
    Svi: 0,
    Lip: 0,
    Srp: 0,
    Kol: 0,
    Ruj: 0,
    Lis: 0,
    Stu: 0,
    Pro: 0,
  };

  // Initialize the monthlyCounts object with 0 for all months
  for (let i = 1; i <= 12; i++) {
    const month = i.toString().padStart(2, '0');
    monthlyCounts[getMonthCount(month)] = 0;
  }

  // Update the monthlyCounts object with the actual counts
  for (const data of orderedTest) {
    const date = new Date(data.date);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');

    monthlyCounts[getMonthCount(month)] = data.numberOfMembers;
  }

  return monthlyCounts;
}

/**@markoboras0712 internationalize this */
export function getMonthlyTotalIncome(
  groupsMembersData: GroupMembersDataPoint[],
): MonthlyMembersCount {
  const monthlyCounts: MonthlyMembersCount = {
    Sij: 0,
    Velj: 0,
    Ožu: 0,
    Tra: 0,
    Svi: 0,
    Lip: 0,
    Srp: 0,
    Kol: 0,
    Ruj: 0,
    Lis: 0,
    Stu: 0,
    Pro: 0,
  };

  // Initialize the monthlyCounts object with 0 for all months
  for (let i = 1; i <= 12; i++) {
    const month = i.toString().padStart(2, '0');
    monthlyCounts[getMonthCount(month)] = 0;
  }

  // Update the monthlyCounts object with the actual counts
  for (const data of groupsMembersData) {
    const date = new Date(data.date);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');

    monthlyCounts[getMonthCount(month)] = data.totalIncome;
  }

  return monthlyCounts;
}

export function getGroupsGraphRevenueData(option: string) {
  const groups = useGroups();
  const groupAnalytics = useGroupAnalyticsSelector();
  const currentGroup = groups?.find((group) => group.name === option);

  const filteredGroupAnalytics = groupAnalytics?.groupsData
    ?.filter((group) => group.groupId === currentGroup?.id)
    ?.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA.getTime() - dateB.getTime();
    });

  // Get the monthly total income count
  const totalIncome = getMonthlyTotalIncome(filteredGroupAnalytics);

  return { totalIncome };
}

/**@markoboras0712 internationalize this */
export function getGroupsGraphData(option: string) {
  const groups = useGroups();
  const groupAnalytics = useGroupAnalyticsSelector();
  const currentGroup = groups?.find((group) => group.name === option);

  const labels = [
    'Sij',
    'Velj',
    'Ožu',
    'Tra',
    'Svi',
    'Lip',
    'Srp',
    'Kol',
    'Ruj',
    'Lis',
    'Stu',
    'Pro',
  ];

  const filteredGroupAnalytics = groupAnalytics?.groupsData
    ?.filter((group) => group.groupId === currentGroup?.id)
    ?.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateA.getTime() - dateB.getTime();
    });

  // Get the monthly members count
  const monthlyMembersCount = getMonthlyMembersCount(filteredGroupAnalytics);

  return {
    labels,
    datasets: [
      {
        data: monthlyMembersCount,
        borderColor: '#ACEF34',
        backgroundColor: (context: ScriptableContext<'bar'>) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 2000, 800);
          gradient.addColorStop(0.03, 'rgba(133, 185, 40, 1)');
          gradient.addColorStop(0.5365, 'rgba(177, 211, 125, 0.4)');
          gradient.addColorStop(0.9203, 'rgba(211, 232, 190, 0.1)');

          return gradient;
        },
        fill: 'start',
      },
    ],
  };
}
