import { IntlShape } from 'react-intl';

export const getFacilityDiscoverPriorityList = (intl: IntlShape) => [
  {
    value: 'high',
    label: intl.formatMessage({
      id: 'input.discoverPriority.high',
      defaultMessage: 'High',
    }),
  },
  {
    value: 'medium',
    label: intl.formatMessage({
      id: 'input.discoverPriority.medium',
      defaultMessage: 'Medium',
    }),
  },
  {
    value: 'low',
    label: intl.formatMessage({
      id: 'input.discoverPriority.low',
      defaultMessage: 'Low',
    }),
  },
];

export const getFacilityTypeList = (intl: IntlShape) => [
  {
    value: 'sport',
    label: intl.formatMessage({
      id: 'input.facility-type.sport',
      defaultMessage: 'Sport',
    }),
  },
  {
    value: 'group',
    label: intl.formatMessage({
      id: 'input.facility-type.group',
      defaultMessage: 'Group',
    }),
  },
];

export const getFacilityPaymentMethodsList = (intl: IntlShape) => [
  {
    value: 'inFacility',
    label: intl.formatMessage({
      id: 'input.availablePaymentMethods.inFacility',
      defaultMessage: 'In Facility',
    }),
  },
  {
    value: 'card',
    label: intl.formatMessage({
      id: 'input.availablePaymentMethods.card',
      defaultMessage: 'Card',
    }),
  },
];

const SPORTS_LIST: SelectValue[] = [
  { value: 'football', label: 'Football' },
  { value: 'basketball', label: 'Basketball' },
  { value: 'tennis', label: 'Tennis' },
  { value: 'padel', label: 'Padel' },
  { value: 'bocanje', label: 'Boćanje' },
  { value: 'golf', label: 'Golf' },
  { value: 'swimming', label: 'Swimming' },
  { value: 'running', label: 'Running' },
  { value: 'cycling', label: 'Cycling' },
  { value: 'hiking', label: 'Hiking' },
  { value: 'yoga', label: 'Yoga' },
  { value: 'boxing', label: 'Boxing' },
  { value: 'gymnastics', label: 'Gymnastics' },
  { value: 'volleyball', label: 'Volleyball' },
  { value: 'badminton', label: 'Badminton' },
  { value: 'squash', label: 'Squash' },
  { value: 'tableTennis', label: 'Table Tennis' },
  { value: 'airsoft', label: 'Airsoft' },
  { value: 'paintball', label: 'Paintball' },
  { value: 'bubbleFootball', label: 'Bubble Football' },
  { value: 'martialArts', label: 'Martial Arts' },
  { value: 'fitness', label: 'Fitness' },
  { value: 'shooting', label: 'Shooting' },
  { value: 'crossfit', label: 'CrossFit' },
  { value: 'archery', label: 'Archery' },
  { value: 'esports', label: 'eSports' },
  { value: 'dancing', label: 'Dancing' },
  { value: 'skiing', label: 'Skiing' },
  { value: 'snowboarding', label: 'Snowboarding' },
  { value: 'surfing', label: 'Surfing' },
  { value: 'kayaking', label: 'Kayaking' },
  { value: 'rowing', label: 'Rowing' },
  { value: 'climbing', label: 'Climbing' },
  { value: 'bouldering', label: 'Bouldering' },
  { value: 'mountaineering', label: 'Mountaineering' },
  { value: 'skateboarding', label: 'Skateboarding' },
  { value: 'horseRiding', label: 'Horse Riding' },
  { value: 'rugby', label: 'Rugby' },
  { value: 'cricket', label: 'Cricket' },
  { value: 'handball', label: 'Handball' },
  { value: 'futsal', label: 'Futsal' },
  { value: 'lacrosse', label: 'Lacrosse' },
  { value: 'ultimateFrisbee', label: 'Ultimate Frisbee' },
  { value: 'powerlifting', label: 'Powerlifting' },
  { value: 'weightlifting', label: 'Weightlifting' },
  { value: 'pilates', label: 'Pilates' },
  { value: 'zumba', label: 'Zumba' },
  { value: 'kickboxing', label: 'Kickboxing' },
  { value: 'parkour', label: 'Parkour' },
  { value: 'triathlon', label: 'Triathlon' },
  { value: 'scubaDiving', label: 'Scuba Diving' },
  { value: 'snorkeling', label: 'Snorkeling' },
  { value: 'waterPolo', label: 'Water Polo' },
  { value: 'synchronizedSwimming', label: 'Synchronized Swimming' },
  { value: 'fencing', label: 'Fencing' },
  { value: 'karate', label: 'Karate' },
  { value: 'taekwondo', label: 'Taekwondo' },
  { value: 'judo', label: 'Judo' },
  { value: 'muayThai', label: 'Muay Thai' },
  { value: 'wrestling', label: 'Wrestling' },
  { value: 'americanFootball', label: 'American Football' },
  { value: 'iceHockey', label: 'Ice Hockey' },
  { value: 'floorball', label: 'Floorball' },
  { value: 'rollerSkating', label: 'Roller Skating' },
  { value: 'duathlon', label: 'Duathlon' },
  { value: 'paragliding', label: 'Paragliding' },
  { value: 'kitesurfing', label: 'Kitesurfing' },
  { value: 'windsurfing', label: 'Windsurfing' },
  { value: 'aerobics', label: 'Aerobics' },
  { value: 'ballet', label: 'Ballet' },
  { value: 'discGolf', label: 'Disc Golf' },
  { value: 'bowling', label: 'Bowling' },
  { value: 'dodgeball', label: 'Dodgeball' },
  { value: 'pickleball', label: 'Pickleball' },
  { value: 'curling', label: 'Curling' },
  { value: 'canoeing', label: 'Canoeing' },
  { value: 'motorsport', label: 'Motorsport' },
  { value: 'enduranceRacing', label: 'Endurance Racing' },
  { value: 'trailRunning', label: 'Trail Running' },
  { value: 'orienteering', label: 'Orienteering' },
  { value: 'biathlon', label: 'Biathlon' },
  { value: 'pentathlon', label: 'Pentathlon' },
  { value: 'kiteFlying', label: 'Kite Flying' },
  { value: 'roboticsCompetition', label: 'Robotics Competition' },
  { value: 'sauna', label: 'Sauna' },
  { value: 'other', label: 'Other' },
];

export const getFacilitySportsList = (intl: IntlShape) => {
  return SPORTS_LIST.map((sport) => ({
    value: sport.value,
    label: intl.formatMessage({
      id: `input.sports.${sport.value}`,
      defaultMessage: sport.label,
    }),
  }));
};

const PRICING_TYPE_LIST: SelectValue[] = [
  { value: 'perInterval', label: 'Per Interval' },
  { value: 'perSlot', label: 'Per Slot' },
  {
    value: 'oneTime',
    label: 'One Time',
  },
];

const STRIPE_CANCELLATION_TYPE_LIST: SelectValue[] = [
  { value: 'absolute', label: 'Absolute' },
  { value: 'percentage', label: 'Percentage' },
];

const GENERAL_DISCOUNS_SLOT_DISCOUNT_LIST: SelectValue[] = [
  { value: 'general', label: 'General' },
  { value: 'slotNumber', label: 'Slot Number' },
];

const PACKAGES_TYPE_LIST: SelectValue[] = [
  { value: 'perPerson', label: 'Per Person' },
  { value: 'perInterval', label: 'Per Interval' },
];

export const getPackageTypeList = (intl: IntlShape) => {
  return PACKAGES_TYPE_LIST.map((type) => ({
    value: type.value,
    label: intl.formatMessage({
      id: `input.packagedBookingData.packages.type.${type.value}`,
      defaultMessage: type.label,
    }),
  }));
};

export const getGeneralDiscountSlotDiscountList = (intl: IntlShape) => {
  return GENERAL_DISCOUNS_SLOT_DISCOUNT_LIST.map((discount) => ({
    value: discount.value,
    label: intl.formatMessage({
      id: `input.type.${discount.value}`,
      defaultMessage: discount.label,
    }),
  }));
};

export const getStripeCancellationTypeList = (intl: IntlShape) => {
  return STRIPE_CANCELLATION_TYPE_LIST.map((type) => ({
    value: type.value,
    label: intl.formatMessage({
      id: `input.stripeCancellationConfigs.type.${type.value}`,
      defaultMessage: type.label,
    }),
  }));
};

export const getFacilityPricingTypeList = (intl: IntlShape) => {
  return PRICING_TYPE_LIST.map((pricingType) => ({
    value: pricingType.value,
    label: intl.formatMessage({
      id: `input.pricingType.${pricingType.value}`,
      defaultMessage: pricingType.label,
    }),
  }));
};

const AMENITIES_LIST: SelectValue[] = [
  { value: 'parking', label: 'Parking' },
  { value: 'wifi', label: 'WiFi' },
  { value: 'lockerRoom', label: 'Locker Room' },
  { value: 'securedLockerRoom', label: 'Secured Locker Room' },
  { value: 'airConditioning', label: 'Air Conditioning' },
  { value: 'heating', label: 'Heating' },
  { value: 'bar', label: 'Bar' },
  { value: 'restaurant', label: 'Restaurant' },
  { value: 'toilet', label: 'Toilet' },
  { value: 'shower', label: 'Shower' },
  { value: 'seating', label: 'Seating' },
  { value: 'vipSeating', label: 'VIP Seating' },
  { value: 'shop', label: 'Shop' },
  { value: 'sportsShop', label: 'Sports Shop' },
  { value: 'parkingForDisabled', label: 'Parking for Disabled' },
  { value: 'wheelchairAccessible', label: 'Wheelchair Accessible' },
  { value: 'eChargingStation', label: 'E-Charging Station' },
  { value: 'firstAid', label: 'First Aid' },
  { value: 'medicalRoom', label: 'Medical Room' },
  { value: 'spa', label: 'Spa' },
  { value: 'sauna', label: 'Sauna' },
  { value: 'pool', label: 'Pool' },
  { value: 'hotTub', label: 'Hot Tub' },
  { value: 'steamRoom', label: 'Steam Room' },
  { value: 'hydrationStation', label: 'Hydration Station' },
  { value: 'nutritionBar', label: 'Nutrition Bar' },
  { value: 'childPlayArea', label: 'Child Play Area' },
  { value: 'playground', label: 'Playground' },
  { value: 'campingArea', label: 'Camping Area' },
  { value: 'firePit', label: 'Fire Pit' },
  { value: 'multiPurposeHall', label: 'Multi-Purpose Hall' },
  { value: 'basketballHoops', label: 'Basketball Hoops' },
  { value: 'soccerGoals', label: 'Soccer Goals' },
  { value: 'tennisCourts', label: 'Tennis Courts' },
  { value: 'badmintonNets', label: 'Badminton Nets' },
  { value: 'iceBath', label: 'Ice Bath' },
  { value: 'trainingEquipment', label: 'Training Equipment' },
  { value: 'equipmentRental', label: 'Equipment Rental' },
  { value: 'climbingWall', label: 'Climbing Wall' },
  { value: 'skatePark', label: 'Skate Park' },
  { value: 'drinkingFountain', label: 'Drinking Fountain' },
  { value: 'laundryService', label: 'Laundry Service' },
  { value: 'chargingStations', label: 'Charging Stations' },
  { value: 'videoRecordingSystem', label: 'Video Recording System' },
  { value: 'lighting', label: 'Lighting' },
  { value: 'outdoorLighting', label: 'Outdoor Lighting' },
  { value: 'indoorSeating', label: 'Indoor Seating' },
  { value: 'outdoorSeating', label: 'Outdoor Seating' },
  { value: 'securityStaff', label: 'Security Staff' },
  { value: 'cctvSurveillance', label: 'CCTV Surveillance' },
  { value: 'vendingMachines', label: 'Vending Machines' },
  { value: 'bikeRacks', label: 'Bike Racks' },
  { value: 'massageRoom', label: 'Massage Room' },
  { value: 'teamMeetingRoom', label: 'Team Meeting Room' },
  { value: 'breakRoom', label: 'Break Room' },
  { value: 'eventHall', label: 'Event Hall' },
  { value: 'conferenceFacilities', label: 'Conference Facilities' },
  { value: 'lostAndFound', label: 'Lost and Found' },
  { value: 'receptionDesk', label: 'Reception Desk' },
  { value: 'coffeeMachine', label: 'Coffee Machine' },
  { value: 'waterCooler', label: 'Water Cooler' },
  { value: 'towelsProvided', label: 'Towels Provided' },
  { value: 'shoeRental', label: 'Shoe Rental' },
  { value: 'scoreDisplay', label: 'Score Display' },
  { value: 'musicSystem', label: 'Music System' },
];

export const getFacilityAmenitiesList = (intl: IntlShape) => {
  return AMENITIES_LIST.map((amenity) => ({
    value: amenity.value,
    label: intl.formatMessage({
      id: `input.amenities.${amenity.value}`,
      defaultMessage: amenity.label,
    }),
  }));
};
