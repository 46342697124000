import { SerializedStyles } from '@emotion/react';
import { useAuthForm, useFirebaseAuth } from '@modules/auth/hooks';
import { NewButton } from '@ui/Button';
import { FormInput } from '@ui/FormInput/FormInput';
import { motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { loginFormStyles as styles } from './styles';

const Mail = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/mail.svg'),
  {
    loading: () => null,
  },
);

const Lock = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/lock.svg'),
  {
    loading: () => null,
  },
);
const GoogleIcon = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/google.svg'),
  {
    loading: () => null,
  },
);

export const LoginForm: React.FC = () => {
  const { formatMessage } = useIntl();
  const { handleGoogleLogin } = useFirebaseAuth();
  const { formik, loading } = useAuthForm('login');

  return (
    <motion.form
      style={{
        opacity: 0,
        y: 8,
      }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1.5, delay: 0.4, type: 'spring' }}
      css={styles.formStyles}
      autoComplete="off"
      onSubmit={formik.handleSubmit}
    >
      <FormInput
        type="email"
        label={formatMessage({ id: 'login-form.email.label' })}
        id="email"
        name="email"
        Icon={Mail}
        value={formik.values.email}
        error={formik.errors.email}
        touched={formik.touched}
        isRequired
        placeholder={formatMessage({ id: 'login-form.email.placeholder' })}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />

      <FormInput
        placeholder={formatMessage({ id: 'login-form.password.placeholder' })}
        type="password"
        label={formatMessage({ id: 'login-form.password.label' })}
        id="password"
        name="password"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        touched={formik.touched}
        Icon={Lock}
        isRequired
        value={formik.values.password}
        error={formik.errors.password}
        isPasswordInput
      />
      <div css={styles.switchText}>
        <p>
          <Link href="/forgot-password">
            <FormattedMessage id="login-form.reset.link" />
          </Link>
        </p>
      </div>
      <div css={styles.ctaButtons}>
        <NewButton disabled={loading} type="submit" size="large">
          <FormattedMessage id="login-form.button" />
        </NewButton>
      </div>

      {/* <div>
        <p css={styles.switchText}>
          <FormattedMessage id="login-form.register.label" />
          <Link href={`register`}>
            <FormattedMessage id="login-form.register.link" />
          </Link>
        </p>
      </div> */}
      <div css={styles.otherAccLoginContainer}>
        <p>
          <FormattedMessage id="login-form.other.description" />
        </p>
        <div css={styles.registerBtnContainer}>
          <NewButton
            onClick={handleGoogleLogin}
            disabled={loading}
            type="button"
            variant="outline"
            className="w-full"
            size="large"
          >
            <GoogleIcon viewBox="0 1 15 15" />
            <FormattedMessage id="login-form.google.label" />
          </NewButton>
        </div>
      </div>
    </motion.form>
  );
};
