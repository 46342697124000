import { useCallback } from 'react';
import {
  ArrayPath,
  FieldArray,
  useFieldArray,
  UseFormReturn,
} from 'react-hook-form';

interface UseDynamicListInputsProps<T> {
  name: keyof T;
  form: UseFormReturn<T>;
  defaultValue: T[keyof T];
}

export function useDynamicListInputs<T>({
  name,
  form,
  defaultValue,
}: UseDynamicListInputsProps<T>) {
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: name as unknown as ArrayPath<T>,
  });

  const handleAppend = useCallback(
    () =>
      append(
        defaultValue as
          | FieldArray<T, ArrayPath<T>>
          | FieldArray<T, ArrayPath<T>>[],
      ),
    [append, defaultValue],
  );
  const handleRemove = useCallback((index: number) => remove(index), [remove]);

  return { fields, handleAppend, handleRemove };
}
