import { SerializedStyles } from '@emotion/react';
import { useFacility } from '@modules/facility';
import { useEditDisplayName } from '@modules/users';
import { useClickOutsideElement } from '@shared/hooks';
import { NewButton } from '@ui/Button';
import { FormInput } from '@ui/FormInput';
import { AnimatePresence, motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { styles } from './styles';

const Mail = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/mail.svg'),
  {
    loading: () => null,
  },
);

interface Props {
  email: string;
  defaultDisplayNameValue: string;
  closeModal: () => void;
}

export const EditUserModal: React.FC<Props> = ({
  email,
  defaultDisplayNameValue,
  closeModal,
}) => {
  const { formatMessage } = useIntl();

  const facility = useFacility();
  const [loading, setLoading] = useState(false);
  const [displayName, setDisplayName] = useState(defaultDisplayNameValue);

  const { editDisplayName } = useEditDisplayName();
  const closeRef = useClickOutsideElement<HTMLDivElement>(closeModal);

  function handleDisplayNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setDisplayName(event.target.value);
  }

  async function handleSubmit() {
    setLoading(true);

    await editDisplayName({
      email,
      displayName,
      facilityId: facility?.id || '',
    });

    closeModal();
    setLoading(false);
  }

  return (
    <AnimatePresence>
      <motion.div
        css={styles.wrapper}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <div css={styles.base} ref={closeRef}>
          <div css={styles.columnOne}>
            <h2 css={styles.title}>
              <FormattedMessage id="edit-role.modal.title" />
            </h2>
            <h3 css={styles.description}>
              <FormattedMessage id="edit-role.modal.description" />
            </h3>
            <h3 css={styles.confirmDescription}>
              <FormattedMessage id="edit-role.modal.confirm.description" />
            </h3>
            <FormInput
              type="text"
              label={formatMessage({
                id: 'users-management-form.displayName.label',
              })}
              id="displayName"
              name="displayName"
              Icon={Mail}
              value={displayName}
              placeholder={formatMessage({
                id: 'users-management-form.displayName.placeholder',
              })}
              onChange={handleDisplayNameChange}
            />

            <div className="flex mt-24 gap-12">
              <NewButton
                type="button"
                loading={loading}
                disabled={loading}
                onClick={handleSubmit}
              >
                <FormattedMessage id="edit-role.modal.confirm" />
              </NewButton>
              <NewButton variant="ghost" type="button" onClick={closeModal}>
                <FormattedMessage id="edit-role.modal.cancel" />
              </NewButton>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
