import { css } from '@emotion/react';
import { button } from '@styles/button.styles';
import { breakpoints } from '@styles/variables.styles';
import { AnimatedText } from '@ui/AnimatedText';
import { animations } from 'const';
import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import Globe from 'public/icons/globe.svg';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const Community: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <section className="w-full max-w-[1600px] mx-auto px-16 lg:px-48 overflow-hidden py-[120px] gap-40 lg:gap-80 flex flex-col md:flex-row">
      <motion.div
        className="w-full md:max-w-max flex flex-col gap-8 my-auto"
        initial="hidden"
        whileInView="visible"
        variants={{
          visible: { transition: { staggerChildren: 0.1 } },
        }}
        viewport={{ once: true }}
      >
        <div className="flex flex-col xsm:flex-row gap-8 w-full">
          {[...Array(2)].map((_, i) => (
            <motion.div
              key={i}
              className="relative rounded-[10px] object-cover max-md:w-full"
              initial={{
                filter: 'blur(20px)',
              }}
              whileInView={{
                filter: 'blur(0px)',
                transition: {
                  duration: 0.6,
                  ease: [0.65, 0.01, 0.02, 0.98],
                },
              }}
            >
              <Image
                className="rounded-[10px] object-cover max-md:w-full"
                key={i}
                src={`/images/community/community-${i + 1}.jpg`}
                alt="Community"
                width={365}
                height={263}
              />
              <div className="absolute top-0 left-0 w-full h-full border border-background-1 bg-transparent rounded-[10px]" />
            </motion.div>
          ))}
        </div>
        <motion.div
          className="flex flex-col xsm:flex-row gap-8 w-full"
          initial="hidden"
          whileInView="visible"
          variants={{
            visible: { transition: { staggerChildren: 0.1 } },
          }}
          viewport={{ once: true }}
        >
          {[...Array(2)].map((_, i) => (
            <motion.div
              key={i}
              className="rounded-[10px] object-cover max-md:w-full"
              initial={{
                filter: 'blur(20px)',
              }}
              whileInView={{
                filter: 'blur(0px)',
                transition: {
                  duration: 0.6,
                  ease: [0.65, 0.01, 0.02, 0.98],
                },
              }}
            >
              <Image
                className="relative rounded-[10px] object-cover max-md:w-full"
                key={i}
                src={`/images/community/community-${i + 3}.jpg`}
                alt="Community"
                width={365}
                height={263}
              />
              <div className="absolute top-0 left-0 w-full h-full border border-background-1 bg-transparent rounded-[10px]" />
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
      <div className="w-full md:max-w-[488px] my-auto flex flex-col gap-20 max-md:-order-1">
        <motion.div
          className="flex gap-12 items-center"
          {...animations.opacityInView({})}
        >
          <Globe
            className="[&>path]:fill-primary w-12 h-12"
            viewBox="0 0 17 16"
          />
          <h3 className="text-primary text-small uppercase tracking-[3px]">
            <FormattedMessage id="landing.community.subtitle" />
          </h3>
        </motion.div>
        <AnimatedText
          className="text-text-6 text-xlarge leading-8 -tracking-[0.03em] font-bold"
          wrapperElement="h2"
          text={formatMessage({ id: 'landing.community.title' })}
        />
        <AnimatedText
          className="text-text-5 text-base"
          wrapperElement="p"
          text={formatMessage({ id: 'landing.community.description' })}
          delay={0.2}
        />

        <motion.div {...animations.opacityInView({ delay: 0.4 })}>
          <Link href="/contact" css={styles.button}>
            <span>
              <FormattedMessage id="landing.community.digitilize" />
            </span>
          </Link>
        </motion.div>
      </div>
    </section>
  );
};

const styles = {
  button: css`
    ${button.primary};
    display: inline-flex;
    color: var(--color-general-primary);
    padding: 0px 32px;
    font-family: var(--font-family-primarySemiBold);
    font-size: 16px;
    line-height: 24px;
    height: 44px;

    color: var(--color-text-1);
    background-color: var(--color-general-primary);

    border-radius: 20px;

    @media ${breakpoints.toSml} {
      width: 100%;
    }

    &:hover,
    &:focus,
    &:active {
      color: var(--color-text-1);
    }
  `,
};
