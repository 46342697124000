import { useScrollIntoView } from '@shared/hooks';
import { useCallback } from 'react';
import {
  FieldValues,
  Path,
  UseControllerProps,
  useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

interface Option<T> {
  value: T;
  label: string;
}

interface UseMultipleSelectProps<TFieldValues extends FieldValues, T> {
  name: Path<TFieldValues>;
  options: Option<T>[];
  isRequired?: boolean;
  rules?: UseControllerProps<TFieldValues>['rules'];
  onChangeHandler?: (values: T[]) => void;
}

export const useMultipleSelect = <
  TFieldValues extends FieldValues,
  T = string,
>({
  name,
  options,
  isRequired = false,
  rules,
  onChangeHandler,
}: UseMultipleSelectProps<TFieldValues, T>) => {
  const { formatMessage } = useIntl();
  const { handleFocus } = useScrollIntoView();
  const form = useFormContext<TFieldValues>();

  const {
    formState: { errors },
  } = form;

  const handleMultiSelectChange = useCallback(
    (field: FieldValues) => (selectedOptions: Option<T>[] | null) => {
      const selectedValues = selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [];
      field.onChange(selectedValues);
      onChangeHandler?.(selectedValues);
    },
    [onChangeHandler],
  );

  const getMultiValue = (data: T[] | null): Option<T>[] | null => {
    if (!data) return null;

    return data.map((value) => {
      const option = options.find((option) => option.value === value);
      return option || { value, label: String(value) };
    });
  };

  const getFieldRules = () => ({
    ...(isRequired
      ? {
          required: formatMessage({
            id: 'input.required',
          }),
        }
      : {}),
    ...rules,
  });

  return {
    form,
    errors,
    handleFocus,
    handleMultiSelectChange,
    getMultiValue,
    getFieldRules,
  };
};
