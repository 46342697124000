import clsx from 'clsx';
import SpinnerIcon from 'public/icons/loading.svg';
import React from 'react';

type Variant =
  | 'primary'
  | 'outline'
  | 'secondary'
  | 'outline-green'
  | 'ghost'
  | 'ghost-unpadded'
  | 'icon'
  | 'delete'
  | 'delete-outline';

type Size = 'small' | 'medium' | 'large';

const baseClass =
  'flex items-center justify-center gap-8 font-bold no-underline rounded-[32px] transition-all duration-200 ease-out disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap';

const loadingClasses = `events-none cursor-not-allowed`;

const variantStyles: Record<Variant, string> = {
  primary:
    'bg-primary text-text-1 hover:bg-primary-darker-1 hover:text-white focus:bg-primary-darker-1 focus:text-white disabled:bg-primary-lighter-1',
  outline:
    'bg-transparent border-[1px] border-border-5 text-text-5 hover:border-border-4',
  secondary: 'bg-background-5 text-text-5 hover:bg-background-4',
  'outline-green':
    'text-primary border-[1px] border-border-1 bg-transparent hover:text-primary-lighter-1 hover:border-primary-lighter-1 focus:text-primary-lighter-1 focus:border-primary-lighter-1',
  ghost: 'bg-transparent text-text-5 hover:text-text-6 focus:text-text-6',
  'ghost-unpadded':
    '!p-0 bg-transparent text-text-5 hover:text-text-6 focus:text-text-6',
  icon: 'bg-transparent border-[1px] border-border-5 hover:border-border-4',
  delete: 'bg-[#E2412B] text-white hover:bg-[#F37C6C] focus:bg-[#F37C6C]',
  'delete-outline':
    'bg-transparent border-[1px] border-[#E2412B] text-[#E2412B] hover:border-[#F37C6C] hover:text-[#F37C6C]',
};

const sizeStyles: Record<Size, string> = {
  small: 'px-16 py-4 text-micro-fluid -tracking-1 h-24',
  medium: 'px-20 py-8 text-small-fluid h-36',
  large: 'px-32 py-10 text-base-fluid h-44',
};

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variant;
  size?: Size;
  children?: React.ReactNode;
  iconBefore?: React.ReactNode;
  iconAfter?: React.ReactNode;
  iconButton?: boolean;
  loading?: boolean;
  href?: string;
  square?: boolean;
}

export const NewButton = ({
  variant = 'primary',
  size = 'medium',
  children,
  iconBefore,
  iconAfter,
  iconButton,
  disabled,
  loading,
  square,
  ...props
}: ButtonProps) => {
  return (
    <button
      {...props}
      disabled={disabled}
      className={clsx(
        baseClass,
        sizeStyles[size],
        variantStyles[variant],
        loading && loadingClasses,
        iconButton && 'rounded-full w-[initial] h-[initial] px-8 py-8',
        square && 'rounded-[8px]',
        props.className,
      )}
    >
      {loading && (
        <SpinnerIcon
          className={`fill-white inline mr-4 ${
            size === 'small' ? 'w-12 h-12' : 'w-16 h-16'
          } text-white
       animate-spin`}
        />
      )}
      {iconBefore}
      {children}
      {iconAfter}
    </button>
  );
};
