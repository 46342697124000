import { AnimatedText } from '@ui/AnimatedText';
import { animations } from 'const/animations';
import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { styles } from './styles';

export const Hero: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <section id="hero" css={styles.base}>
      <div css={styles.wrapper}>
        <div css={styles.present}>
          <motion.p {...animations.opacity({})}>
            <FormattedMessage id="landing.hero.intro" />
          </motion.p>
        </div>
        <h1 css={styles.title}>
          <AnimatedText
            text={formatMessage({ id: 'landing.hero.title' })}
            delay={0.2}
          />
        </h1>
        <div css={styles.paragraphWrapper}>
          <AnimatedText
            delay={1}
            wrapperElement="p"
            className="flex justify-center"
            text={formatMessage({ id: 'landing.hero.paragraph1' })}
          />
        </div>
        <motion.div
          {...animations.opacityInView({
            duration: 1,
            amount: 0.1,
            delay: 1.6,
          })}
          css={styles.image}
        >
          <Image
            src="/images/hero-mockup.png"
            alt="Web app"
            width={996}
            height={524}
            style={{ width: '100%', height: 'auto' }}
            fetchPriority="high"
            priority
          />
        </motion.div>
        <div css={styles.buttonContainer}>
          <motion.div
            {...animations.opacityInView({ duration: 1, delay: 0.5 })}
            className="w-full max-w-[200px]"
          >
            <Link href="/why-playoff" css={styles.infoButton}>
              <FormattedMessage id="landing.info-button" />
            </Link>
          </motion.div>
          <motion.div
            {...animations.opacityInView({ duration: 1, delay: 0.5 })}
            className="w-full max-w-[200px]"
          >
            <Link href="/contact" css={styles.contactButton}>
              <FormattedMessage id="landing.contact-button" />
            </Link>
          </motion.div>
        </div>
      </div>
    </section>
  );
};
