import axios from 'axios';

interface GeocodeResult {
  latitude: number;
  longitude: number;
  address: string;
}

export class GeolocationService {
  private static API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;
  private static BASE_URL = 'https://maps.googleapis.com/maps/api';

  /**
   * Geocodes an address into latitude and longitude.
   * @param address The address to geocode.
   * @returns A promise that resolves to the latitude and longitude.
   */
  static async geocodeAddress(address: string): Promise<GeocodeResult> {
    try {
      const response = await axios.get(`${this.BASE_URL}/geocode/json`, {
        params: {
          address,
          key: this.API_KEY,
        },
      });

      const { results } = response.data;
      console.log({ results });
      if (results?.length > 0) {
        const location = results[0].geometry.location;

        return {
          latitude: location.lat,
          longitude: location.lng,
          address: results[0].formatted_address,
        };
      }

      return { latitude: 0, longitude: 0, address: '' };
    } catch (error) {
      console.error('Geocoding failed:', error);
      throw error;
    }
  }

  /**
   * Reverse geocodes latitude and longitude into an address.
   * @param latitude The latitude to reverse geocode.
   * @param longitude The longitude to reverse geocode.
   * @returns A promise that resolves to the formatted address.
   */
  static async reverseGeocode(
    latitude: number,
    longitude: number,
  ): Promise<GeocodeResult> {
    try {
      const response = await axios.get(`${this.BASE_URL}/geocode/json`, {
        params: {
          latlng: `${latitude},${longitude}`,
          key: this.API_KEY,
        },
      });

      const { results } = response.data;
      if (results.length > 0) {
        return {
          latitude,
          longitude,
          address: results[0].formatted_address,
        };
      }
      throw new Error('No results found for the given coordinates.');
    } catch (error) {
      console.error('Reverse geocoding failed:', error);
      throw error;
    }
  }

  /**
   * Validates if a given address is valid by attempting to geocode it.
   * @param address The address to validate.
   * @returns A promise that resolves to true if valid, otherwise false.
   */
  static async validateAddress(address: string): Promise<boolean> {
    try {
      const result = await this.geocodeAddress(address);
      return !!result.latitude && !!result.longitude;
    } catch {
      return false;
    }
  }
}
