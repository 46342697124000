import {
  ErrorMessage,
  FieldValuesFromFieldErrors,
} from '@hookform/error-message';
import clsx from 'clsx';
import React from 'react';
import { FieldErrors, FieldName } from 'react-hook-form';
import { FormattedMessage, PrimitiveType } from 'react-intl';
import { WarningIcon } from './WarningIcon';

export const labelBaseClasses =
  'text-text-4 block text-label tracking-3px uppercase';
const labelErrorClasses = 'text-error';

interface FieldWrapperProps<T extends Record<string, any>> {
  name: FieldName<FieldValuesFromFieldErrors<FieldErrors<T>>>;
  errors?: FieldErrors<T>;
  labelId?: string;
  isRequired?: boolean;
  errorClassName?: string;
  helpTextId?: string;
  helpTextClassName?: string;
  Icon?: React.ComponentType<{ viewBox?: string; className?: string }>;
  children?: React.ReactNode;
  className?: string;
  values?: Record<string, PrimitiveType>;
}

export const FieldWrapper = <T extends Record<string, any>>({
  labelId,
  isRequired,
  errors,
  errorClassName,
  helpTextClassName,
  name,
  helpTextId,
  Icon,
  children,
  className,
  values,
}: FieldWrapperProps<T>) => {
  return (
    <>
      <div className={className || ''}>
        {labelId && (
          <label
            htmlFor={name}
            className={clsx(
              labelBaseClasses,
              errors?.[name]?.message && labelErrorClasses,
            )}
          >
            <FormattedMessage id={labelId} values={values} />{' '}
            {isRequired && (
              <span className="text-border-1 ml-[-4px] text-label tracking-3px">
                *
              </span>
            )}
          </label>
        )}
        <div className="relative mt-4">
          {Icon && (
            <div className="pointer-events-none absolute inset-y-0 left-0 items-center flex pl-16">
              <Icon className="w-16 h-16" />
            </div>
          )}{' '}
          {children}
          {errors?.[name]?.message && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-12">
              <WarningIcon className="w-20 h-20 text-error" />
            </div>
          )}
        </div>
        <ErrorMessage
          name={name}
          errors={errors as FieldErrors}
          render={({ message }) => (
            <p className={`mt-4 text-tiny-fluid text-error ${errorClassName}`}>
              {message}
            </p>
          )}
        />
        {helpTextId && (
          <p
            className={`mt-4 text-small-fluid text-text-5 ${helpTextClassName}`}
          >
            <FormattedMessage id={helpTextId} />
          </p>
        )}
      </div>
    </>
  );
};
