/**
 * Represents a location with a name and a postal code.
 */
interface Location {
  location: string;
  postalCode: string;
}

/**
 * Generates a list of Croatian postal codes from a JSON file.
 *
 * Each postal code is an object with 'label' and 'value' properties.
 * The 'label' is a string in the format "location, postalCode".
 * The 'value' is a string in the same format, but without the space after the comma.
 *
 * @type {SelectValue[]}
 */
export const croatianPostalCodes: SelectValue[] = Array.from<Location>(
  require('public/fixtures/croatian-postal-codes.json'),
).map(
  ({ location, postalCode }): SelectValue => ({
    label: `${location}, ${postalCode}`,
    value: `${location},${postalCode}`,
  }),
);
