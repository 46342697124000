import { firebaseStorage } from '@modules/firebase';
import { FirebaseService } from '@modules/firebase/initFirebase';
import { doc, updateDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { toast } from 'sonner';

interface Props {
  name: string;
  storagePath: string;
  firestorePath?: string;
  maxFileSize?: number;
  acceptedTypes?: string[];
}

export function useImageUploader({
  name,
  storagePath,
  firestorePath,
  maxFileSize = 5 * 1024 * 1024,
  acceptedTypes = ['image/*'],
}: Props) {
  const { formatMessage } = useIntl();
  const { setValue } = useFormContext();
  const existingImageUrl = useWatch({ name });

  const [isLoading, setIsLoading] = useState(false);
  const [localFile, setLocalFile] = useState<File | null>(null);
  const [localPreviewUrl, setLocalPreviewUrl] = useState<string | null>(null);
  const [isMarkedForDeletion, setIsMarkedForDeletion] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedTypes.reduce((acc, type) => ({ ...acc, [type]: [] }), {}),
    multiple: false,
    onDrop: (files) => {
      const file = files[0];
      if (file.size > maxFileSize) {
        console.error('File size exceeds the maximum limit.');
        toast.error(
          formatMessage(
            { id: 'input.imageUploader.fileSizeExceeded' },
            { size: maxFileSize / 1024 / 1024 },
          ),
        );
        return;
      }
      handleDrop(file);
    },
  });

  const handleDrop = (file: File) => {
    setLocalFile(file);
    setLocalPreviewUrl(URL.createObjectURL(file));
    setIsMarkedForDeletion(false);
  };

  const handleRemove = () => {
    setLocalFile(null);
    setLocalPreviewUrl(null);
    setIsMarkedForDeletion(true);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      if (isMarkedForDeletion && existingImageUrl) {
        const storageRef =
          firebaseStorage.getStorageRefFromUrl(existingImageUrl);
        await firebaseStorage.removeStorageItem(storageRef.fullPath);
        if (firestorePath) {
          await updateDoc(doc(FirebaseService.firestore, firestorePath), {
            [name]: '',
          });
        }
        setValue(name, '');
      }

      if (localFile) {
        const uploadedUrl = await firebaseStorage.addStorageItem(
          localFile,
          storagePath,
        );
        if (firestorePath) {
          await updateDoc(doc(FirebaseService.firestore, firestorePath), {
            [name]: uploadedUrl,
          });
        }
        setValue(name, uploadedUrl);
      }

      setLocalFile(null);
      setIsMarkedForDeletion(false);
      toast.success(formatMessage({ id: 'input.imageUploader.uploadSuccess' }));
    } catch (error) {
      toast.error(formatMessage({ id: 'input.imageUploader.uploadError' }));
      console.error('Error during submit:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isMarkedForDeletion) {
      setLocalPreviewUrl(existingImageUrl || null);
    }
  }, [existingImageUrl, isMarkedForDeletion]);

  return {
    isLoading,
    localPreviewUrl,
    isMarkedForDeletion,
    dropzoneProps: getRootProps(),
    inputProps: getInputProps(),
    handleRemove,
    handleSubmit,
  };
}
