import { css } from '@emotion/react';
import { breakpoints } from '@styles/variables.styles';

const formStyles = css`
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media ${breakpoints.to1024} {
    justify-content: center;
  }
`;

const inputStyles = css`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  font-size: 16px;
`;

const buttonStyles = css`
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
`;

const switchText = css`
  color: var(--color-text-4);
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 32px;
  transition: all 0.2s ease-out;

  a {
    border: none;
    cursor: pointer;
    color: var(--color-general-primary-1);
    background-color: transparent;

    &:hover {
      transition: all 0.2s ease-out;
      color: var(--color-text-white);
    }
  }
`;

export const forgotPasswordFormStyles = {
  formStyles,
  inputStyles,
  buttonStyles,
  switchText,
};
