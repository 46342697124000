import { css } from '@emotion/react';
import { button } from '@styles/button.styles';
import { scroll } from '@styles/scroll.styles';

export const base = css`
  ${scroll}
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #16141bcc;
  z-index: 3;
  max-height: 100vh;
  overflow: hidden;
`;

const modal = css`
  display: flex;
  gap: 20px;
  flex-direction: column;
  padding: 36px 32px;
  width: 600px;
  background-color: var(--color-background-2);
  border-radius: 8px;
  z-index: 999;
  transform: none;
  max-height: 90%;
  overflow: hidden;
  position: relative;

  input {
    background-color: var(--color-background-2);
  }
`;

const title = css`
  font-size: 20px;
  line-height: 28px;
  color: var(--color-text-6);
  align-items: flex-start;
  display: flex;
  max-width: calc(100% - 130px);
`;

const form = css`
  display: flex;
  gap: 28px;
  flex-direction: column;
  min-height: 420px;
  ${scroll.base}
`;

const buttonConfirm = css`
  ${button.primary};
  padding: 8px 28px;
  background: var(--color-general-primary);
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  border-radius: 32px;
  letter-spacing: -0.01em;

  &:hover,
  &:focus,
  &:active {
    color: var(--color-text-1);
  }
`;

const buttonBack = css`
  border: none;
  cursor: pointer;
  color: var(--color-text-5);
  background-color: var(--color-background-5);
  padding: 8px 24px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: -0.01em;
  transition: all 0.2s ease-out;
  border: 1px solid transparent;

  :hover {
    background-color: var(--color-background-6);
    border-color: var(--color-border-cardBorder);
  }

  :disabled {
    opacity: 0.4;
    cursor: pointer;
  }
`;

const buttonWrapper = css`
  margin-top: 12px;
  display: flex;
  gap: 20px;
`;

const checkboxWrapper = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  gap: 12px;
  padding-top: 20px;
`;

const checkbox = css`
  position: relative;
  display: inline-block;
  min-width: 20px;
  height: 20px;
  border: 1px solid var(--color-border-4);
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;

  input[type='checkbox'] {
    display: none;
  }
`;

const checkboxActive = css`
  ${checkbox};

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 8px;
    border: solid var(--color-general-primary);
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

const repeatText = css`
  color: var(--color-text-6);
  font-size: 14px;
  line-height: 16px;
`;

const label = css`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: var(--color-text-4);
`;

const infoContainer = css`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  width: 100%;
  border: 1px solid var(--color-borderDark);
  border-radius: 16px;
  margin-bottom: 16px;
  margin-top: 16px;
  cursor: pointer;
`;

const packageTitle = css`
  color: white;
  font-family: 'HK Grotesk';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 4px;
`;

const hoursWrapper = css`
  color: var(--color-text-4);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 4px;
`;

const divider = css`
  margin: 32px 0;
`;

const packageDescriptionContainer = css`
  display: flex;
  flex-direction: column;
`;

const radioButtonContainer = css`
  border-bottom: none;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-out;

  input {
    background-color: transparent;
  }
`;

const radioInput = css`
  width: 24px;
  height: 24px;
  cursor: pointer;
  accent-color: var(--color-general-primary);
  border: 1px solid #b7bab2;
  transition: all 0.2s ease-out;
  border-radius: 50%;

  &:checked {
    background-color: transparent;
    border-color: var(--color-general-primary);
    transition: all 0.2s ease-out;
  }

  &:checked::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: var(--color-general-primary);
    border: 1px solid var(--color-general-primary);
    transition: all 0.2s ease-out;
  }
`;

export const styles = {
  base,
  modal,
  title,
  form,
  buttonConfirm,
  buttonBack,
  buttonWrapper,
  packageDescriptionContainer,
  checkboxWrapper,
  radioButtonContainer,
  divider,
  infoContainer,
  radioInput,
  checkbox,
  checkboxActive,
  repeatText,
  hoursWrapper,
  label,
  packageTitle,
};
