import { FormValues, convertPrice } from '@modules/booking-new';
import { useFacility } from '@modules/facility';
import { formInputStyles } from '@ui/FormInput/styles';
import { FC, useEffect, useMemo } from 'react';
import { styles } from '../bookingInfoFormModal.styles';
import { NumberOfPlayersSelect } from './number-of-players-select';
import { NumberOfPlayersOption } from './number-of-players-select/models';

interface Props {
  values: FormValues;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

export const BookingPaintballSelect: FC<Props> = ({
  values,
  setFieldValue,
}) => {
  const currentFacility = useFacility();
  const currentCategory = useMemo(
    () =>
      currentFacility?.packagedBookingData?.categories?.find(
        (item) => values.categoryName === item.name,
      ),
    [currentFacility, values.categoryName, setFieldValue],
  );

  const currentPackage = useMemo(
    () => currentCategory?.packages?.find((item) => values.package === item),
    [currentCategory, values.package],
  );

  function handleCheckboxChange(itemName: string) {
    setFieldValue('categoryName', itemName);
  }

  function handleClick(itemName: string, event: React.MouseEvent) {
    event.stopPropagation();
    handleCheckboxChange(itemName);
  }

  function handlePackageClick(item: Package) {
    const currentSelectedNumberOfPlayers =
      values?.selectedNumberOfPlayers?.value;
    const newAvailableUserNumbers = item?.availableUserNumbers;
    if (newAvailableUserNumbers?.includes(currentSelectedNumberOfPlayers)) {
      setFieldValue('selectedNumberOfPlayers', {
        value: currentSelectedNumberOfPlayers,
        label: `${currentSelectedNumberOfPlayers}`,
      });
    } else {
      setFieldValue('selectedNumberOfPlayers', {
        value: newAvailableUserNumbers?.[0],
        label: `${newAvailableUserNumbers?.[0]}`,
      });
    }
    setFieldValue('package', item);
  }

  function handleSetNumberOfPlayers(option: NumberOfPlayersOption) {
    setFieldValue('selectedNumberOfPlayers', option);
  }

  useEffect(() => {
    if (!currentCategory) return;
    setFieldValue('package', currentCategory?.packages[0], false);
    setFieldValue(
      'selectedNumberOfPlayers',
      {
        value: currentCategory?.packages[0]?.availableUserNumbers?.[0],
        label: `${currentCategory?.packages[0]?.availableUserNumbers?.[0]}`,
      },
      false,
    );
  }, [values.categoryName]);

  return (
    <div>
      <label css={styles.label}>
        Paket<span css={formInputStyles.labelStar}>*</span>
      </label>
      <>
        {currentFacility?.packagedBookingData?.categories?.map((item) => (
          <div key={item.name} css={styles.checkboxWrapper}>
            <label
              css={
                values.categoryName === item.name
                  ? styles.checkboxActive
                  : styles.checkbox
              }
            >
              <input
                type="checkbox"
                checked={values.categoryName === item.name}
                onChange={() => handleCheckboxChange(item.name)}
              />
            </label>
            <span
              css={styles.repeatText}
              onClick={(e) => handleClick(item.name, e)}
            >
              {item.name}
            </span>
          </div>
        ))}

        <div css={styles?.divider} />

        {currentCategory && (
          <NumberOfPlayersSelect
            availableUserNumbers={
              currentPackage?.availableUserNumbers || [1, 2, 3, 4, 5, 6, 7, 8]
            }
            selectedOption={values.selectedNumberOfPlayers}
            onSetSelectedOption={handleSetNumberOfPlayers}
          />
        )}

        <div css={styles?.divider} />

        {currentFacility?.packagedBookingData?.categories
          ?.find((item) => values.categoryName === item.name)
          ?.packages?.map((item) => (
            <div
              onClick={() => handlePackageClick(item)}
              css={styles.infoContainer}
              key={item.name}
            >
              <div css={styles.packageDescriptionContainer}>
                <p css={styles.packageTitle}>{item?.name || ''}</p>
                <div css={styles.hoursWrapper}>
                  {`€${convertPrice(item.price)}`} /{' '}
                  {item?.type === 'perInterval' ? 'terminu' : 'osobi'}{' '}
                  {item?.interval * item.minSlotsInBooking} minuta
                </div>
                <span css={styles.hoursWrapper}>{item.description}</span>
              </div>
              <div css={styles.radioButtonContainer}>
                <input
                  type="radio"
                  name="referral"
                  id={item.name}
                  checked={values.package === item}
                  onChange={() => setFieldValue('package', item)}
                  css={styles.radioInput}
                />
              </div>
            </div>
          ))}
      </>
    </div>
  );
};
