import Image from 'next/image';
import { FC } from 'react';

interface Props {
  facility: CSVFacility;
}

export const FacilityCardImage: FC<Props> = ({ facility }) => {
  const mainSport = facility?.mainSport as Sport;

  if (facility.mainImage) {
    return (
      <div className="relative w-full h-[200px] rounded-[20px] overflow-hidden">
        <Image
          src={facility.mainImage}
          alt={facility.name}
          fill
          sizes="(max-width: 768px) 100vw, 296px" // Adjust for responsiveness
          style={{ objectFit: 'cover' }} // Ensures the aspect ratio is maintained
        />
      </div>
    );
  }

  if (mainSport === 'other') {
    return (
      <div className="relative w-full h-[200px] rounded-[20px] overflow-hidden">
        <Image
          src={`/images/facility-fallbacks/fitness.webp`}
          alt="Facility image"
          fill
          sizes="(max-width: 768px) 100vw, 296px"
          style={{ objectFit: 'cover' }}
        />
      </div>
    );
  }

  if (mainSport) {
    return (
      <div className="relative w-full h-[200px] rounded-[20px] overflow-hidden">
        <Image
          src={`/images/facility-fallbacks/${mainSport}.webp`}
          alt="Facility image"
          fill
          sizes="(max-width: 768px) 100vw, 296px"
          style={{ objectFit: 'cover' }}
        />
      </div>
    );
  }

  return null;
};
