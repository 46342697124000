import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import { TextareaHTMLAttributes, useCallback, useEffect, useRef } from 'react';
import {
  FieldErrors,
  Path,
  RegisterOptions,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { FormattedMessage, PrimitiveType, useIntl } from 'react-intl';
import { InputHelptext } from './InputHelptext';
import { WarningIcon } from './WarningIcon';

type Variant = 'small' | 'medium' | 'large';

const textareaBaseClasses =
  'block w-full rounded-lg bg-background-4 text-white placeholder:text-text-3 transition-all duration-200 ease-out outline-none border border-transparent focus:outline-none focus-visible:outline-none focus:ring-0 resize-none';
const textareaErrorClasses = '!border-error pr-40';
const textareaDisabledClasses = 'disabled:cursor-not-allowed';

const labelBaseClasses = 'text-text-4 block text-label tracking-3px uppercase';
const labelErrorClasses = 'text-error';

const sizeStyles: Record<Variant, string> = {
  small: 'px-16 py-8 text-small-fluid min-h-[80px]',
  medium: 'px-16 py-12 text-base-fluid min-h-[84px]',
  large: 'p-16 text-medium-fluid min-h-[84px]',
};

interface TextareaFieldProps<T>
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: Path<T>;
  labelId?: string;
  placeholderId?: string;
  isRequired?: boolean;
  helpTextId?: string;
  registerOptions?: RegisterOptions<T, Path<T>>;
  className?: string;
  variant?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  values?: Record<string, PrimitiveType>;
}

export const TextareaField = <T,>({
  name,
  labelId,
  placeholderId,
  isRequired = false,
  helpTextId,
  registerOptions,
  variant = 'medium',
  className,
  disabled,
  values,
  rows,
  ...props
}: TextareaFieldProps<T>) => {
  const { formatMessage } = useIntl();
  const { register, formState } = useFormContext<T>();
  const { ref, ...registerProps } = register(name, registerOptions);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const value = useWatch({
    name,
  });

  const handleRef = useCallback(
    (instance: HTMLTextAreaElement | null) => {
      ref(instance);
      textareaRef.current = instance;
    },
    [ref],
  );

  const adjustHeight = useCallback(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, []);

  useEffect(() => {
    adjustHeight();
  }, [value, adjustHeight]);

  return (
    <div className={className || ''}>
      {labelId && (
        <label
          htmlFor={name}
          className={clsx(
            labelBaseClasses,
            formState.errors?.[name]?.message && labelErrorClasses,
          )}
        >
          <FormattedMessage id={labelId} values={values} />{' '}
          {isRequired && (
            <span className="text-border-1 ml-[-4px] text-label tracking-3px">
              *
            </span>
          )}
        </label>
      )}
      <div className="relative mt-4">
        <textarea
          {...registerProps}
          ref={handleRef}
          id={name}
          disabled={disabled}
          rows={rows || 1}
          placeholder={
            placeholderId ? formatMessage({ id: placeholderId }, values) : ''
          }
          className={clsx(
            textareaBaseClasses,
            formState.errors?.[name]?.message && textareaErrorClasses,
            disabled && textareaDisabledClasses,
            sizeStyles[variant],
            'hover:border hover:border-border-4',
            'focus:border focus:border-border-4',
            'max-h-[512px]',
          )}
          {...props}
        />
        {formState.errors?.[name]?.message && (
          <div className="pointer-events-none absolute top-3 right-0 flex items-start pr-12">
            <WarningIcon className="w-20 h-20 text-error" />
          </div>
        )}
      </div>
      <ErrorMessage
        name={name}
        errors={formState.errors as FieldErrors}
        render={({ message }) => (
          <p className="mt-4 text-tiny-fluid text-error">{message}</p>
        )}
      />
      {helpTextId && <InputHelptext helpTextId={helpTextId} />}
    </div>
  );
};
