import { toKebabCase } from '@shared/utils';
import Link from 'next/link';
import Partner from 'public/icons/partner.svg';
import Logo from 'public/logo/playoff-logo-68.svg';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { FacilityCardImage } from '../facility-card-image';

interface Props {
  facility: CSVFacility;
}

export const FacilityCard: FC<Props> = ({ facility }) => {
  const href = toKebabCase(facility.name);

  return (
    <Link className="group flex flex-col" href={`/facility/${href}`}>
      <div className="relative mb-16">
        <FacilityCardImage facility={facility} />
        {facility.firebaseFacilityId && (
          <div className="absolute right-8 bottom-24 max-w-max flex items-center px-12 py-6 rounded-[20px] gap-4 bg-background-2">
            <Logo className="w-[51px] h-12" viewBox="0 0 68 16" />
            <Partner className="w-[50px] h-[10px]" viewBox="0 0 50 10" />
          </div>
        )}
      </div>
      <h3 className="font-primary font-semibold text-base text-white">
        {facility.name}
      </h3>
      <p className="text-small text-primary mb-8">{facility.address}</p>
      <p className="text-small text-text-4 capitalize">
        <FormattedMessage id={`directory.${facility.mainSport}`} />
      </p>
    </Link>
  );
};
