import { useScrollIntoView } from '@shared/hooks';
import { FieldValues, useFormContext } from 'react-hook-form';
import { SingleValue } from 'react-select';

interface SelectOption<T> {
  value: T;
  label: string;
}

interface UseSingleSelectProps<TFieldValues extends FieldValues, T> {
  options: SelectOption<T>[];
  onChangeHandler?: (value: T | null) => void;
}

export const useSingleSelect = <TFieldValues extends FieldValues, T = string>({
  options,
  onChangeHandler,
}: UseSingleSelectProps<TFieldValues, T>) => {
  const { handleFocus } = useScrollIntoView();
  const form = useFormContext<TFieldValues>();

  const {
    formState: { errors },
  } = form;

  const handleSelectChange =
    (field: FieldValues) => (selectedOption: SingleValue<SelectOption<T>>) => {
      field.onChange(selectedOption?.value || null);
      onChangeHandler?.(selectedOption?.value || null);
    };

  const getValue = (data: T | null): SingleValue<SelectOption<T>> => {
    if (!data) return null;
    return options?.find((option) => option.value === data) || null;
  };

  return {
    form,
    errors,
    handleFocus,
    handleSelectChange,
    getValue,
  };
};
