import Image from 'next/image';
import Link from 'next/link';
import XIcon from 'public/icons/X-16.svg';
import FacebookIcon from 'public/icons/facebook-16.svg';
import InstagramIcon from 'public/icons/instagram-16.svg';
import LinkedInIcon from 'public/icons/linkedin-24.svg';
import FooterLogo from 'public/logo/playoff-logo-108.svg';
import versionFile from 'public/version.json';
import { FormattedMessage } from 'react-intl';
import { styles } from './styles';

export const Footer: React.FC = () => {
  return (
    <footer css={styles.container}>
      <div css={styles.leftSideWrapper}>
        <div css={styles.logoWrapper}>
          <Link
            css={styles.anchor}
            href="/"
            target="_self"
            rel="noopener noreferrer"
            aria-label="PlayOff"
          >
            <FooterLogo css={styles.companyName} />
          </Link>
          <small>{versionFile.currentVersion}</small>
        </div>
        <p css={styles.description}>
          <FormattedMessage id="footer.description" />
        </p>
        <div css={styles.storeImagesWrapper}>
          <a
            href="https://play.google.com/store/apps/details?id=com.sportskiobjekti.app"
            target="_blank"
            rel="noreferrer noopener"
          >
            <Image
              src="/images/google-play.jpg"
              alt="Google Play"
              width={148}
              style={{ width: '148px', height: '44px' }}
              height={52}
              loading="lazy"
            />
          </a>
          <a
            href="https://apps.apple.com/hr/app/playoff/id6449152333"
            target="_blank"
            rel="noreferrer noopener"
          >
            <Image
              src="/images/app-store.jpg"
              alt="App Store"
              width={148}
              style={{ width: '148px', height: '44px' }}
              height={52}
              loading="lazy"
            />
          </a>
        </div>

        <div css={styles.socialsWrapper}>
          <a
            css={styles.socials}
            href="https://www.facebook.com/theplayoffapp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </a>
          <a
            css={styles.socials}
            href="https://www.instagram.com/theplayoff.app/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramIcon />
          </a>
          <a
            css={styles.socials}
            href="https://x.com/theplayoffapp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <XIcon />
          </a>
          <a
            css={styles.socials}
            href="https://www.linkedin.com/company/theplayoffapp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon />
          </a>
          {/* <a
            css={styles.socials}
            href="/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <TikTokIcon />
          </a> */}
        </div>
      </div>
      <div css={styles.linkWrapperOne}>
        <p css={styles.linkHeader}>
          <FormattedMessage id="footer.product" />
        </p>
        <Link css={styles.footerLink} href="/">
          <FormattedMessage id="footer.home" />
        </Link>
        {/* <Link
          href="/search"
          target="_blank"
          rel="noopener noreferrer"
          css={styles.footerLink}
        >
          O aplikaciji
        </Link> */}
        {/* <Link
          href="/search"
          target="_blank"
          rel="noopener noreferrer"
          css={styles.footerLink}
        >
          Postani naš partner
        </Link> */}
        {/* <Link
          href="/search"
          target="_blank"
          rel="noopener noreferrer"
          css={styles.footerLink}
        >
          Učestala pitanja
        </Link> */}
        {/* <Link
          href="/search"
          target="_blank"
          rel="noopener noreferrer"
          css={styles.footerLink}
        >
          Kontakt
        </Link> */}
      </div>
      <div css={styles.linkWrapperTwo}>
        <p css={styles.linkHeader}>
          <FormattedMessage id="footer.download-app" />
        </p>
        <Link
          href="https://apps.apple.com/hr/app/playoff/id6449152333"
          css={styles.footerLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="footer.app-store" />
        </Link>
        <Link
          href="https://play.google.com/store/apps/details?id=com.sportskiobjekti.app"
          css={styles.footerLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="footer.app-google-play" />
        </Link>
      </div>
      <div css={styles.linkWrapperThree}>
        <p css={styles.linkHeader}>
          <FormattedMessage id="footer.legal-questions" />
        </p>
        <Link
          href="/privacy-policy"
          css={styles.footerLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="footer.privacy-policy" />
        </Link>
        <Link
          href="/terms-of-service"
          css={styles.footerLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="footer.terms-of-service" />
        </Link>
      </div>
    </footer>
  );
};
