import { countries } from 'countries-list';

export class CountriesListService {
  static getCountryCodeList(): SelectValue[] {
    const values = Object.keys(countries)
      .map((code) => ({
        value: code, // ISO 3166-1 alpha-2 code
        label: code, // Same as value for display, in lowercase
      }))
      .sort((a, b) => (a.value < b.value ? -1 : 1));

    return values;
  }

  static getCountryList(): SelectValue[] {
    const values = Object.values(countries)
      .map((country) => ({
        value: country.name,
        label: country.name,
      }))
      .sort((a, b) => (a.value < b.value ? -1 : 1));

    return values;
  }

  static getPhonePrefixList(): SelectValue[] {
    const set = new Set(
      Object.values(countries).map((value) => `+${value.phone}`),
    );

    const phonePrefixLis = Array.from(set).map((phone) => ({
      label: phone,
      value: phone,
    }));

    return phonePrefixLis;
  }
}
