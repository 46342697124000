import { SerializedStyles } from '@emotion/react';
import { FormValues } from '@modules/booking-new';
import {
  OpponentOption,
  OpponentSelect,
  TeamGameSelect,
} from '@modules/pyramid';
import { FormInput } from '@ui/FormInput';
import { FormikErrors, FormikTouched } from 'formik';
import dynamic from 'next/dynamic';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { styles } from '../bookingInfoFormModal.styles';

const User = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/user-16.svg'),
  {
    loading: () => null,
  },
);

const Mail = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/mail.svg'),
  {
    loading: () => null,
  },
);

interface Props {
  values: FormValues;
  errors: FormikErrors<FormValues>;
  touched: FormikTouched<FormValues>;
  isPyramidChecked: boolean;
  isTeamChecked: boolean;
  teamGameOption: OpponentOption;
  selectedOption: OpponentOption;
  currentFacility: Facility;
  handleBlur: (e: React.FocusEvent<any>) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setIsPyramidChecked: (value: boolean) => void;
  setIsTeamChecked: (value: boolean) => void;
  setSelectedOption: (value: OpponentOption) => void;
  setTeamGameOption: (value: OpponentOption) => void;
}

export const BookingGameModeSelect: FC<Props> = ({
  values,
  errors,
  touched,
  isPyramidChecked,
  isTeamChecked,
  teamGameOption,
  selectedOption,
  currentFacility,
  handleBlur,
  handleChange,
  setFieldValue,
  setIsPyramidChecked,
  setIsTeamChecked,
  setSelectedOption,
  setTeamGameOption,
}) => {
  const { formatMessage } = useIntl();

  function handleTeamGameChange() {
    if (isPyramidChecked) {
      setIsPyramidChecked(!isPyramidChecked);
      setSelectedOption(null);
    }
    setIsTeamChecked(!isTeamChecked);
  }

  function handlePyramidGameChange() {
    if (isTeamChecked) {
      setIsTeamChecked(!isTeamChecked);
      setFieldValue('teamOpponentName', '');
      setFieldValue('teamOpponentEmail', '');
    }
    setIsPyramidChecked(!isPyramidChecked);
  }

  return (
    <>
      {/**Sport Team Game */}
      <div>
        <label css={styles.label}>
          <FormattedMessage id="booking-info-form-modal.game-mode" />
        </label>
        <div css={styles.checkboxWrapper}>
          <label css={isTeamChecked ? styles.checkboxActive : styles.checkbox}>
            <input
              type="checkbox"
              checked={isTeamChecked}
              onChange={handleTeamGameChange}
            />
          </label>
          <span css={styles.repeatText}>
            <FormattedMessage id="booking-info-form-modal.team-game" />
          </span>
        </div>

        {currentFacility.lmsLeagueConfig && (
          <div css={styles.checkboxWrapper}>
            <label
              css={isPyramidChecked ? styles.checkboxActive : styles.checkbox}
            >
              <input
                type="checkbox"
                checked={isPyramidChecked}
                onChange={handlePyramidGameChange}
              />
            </label>

            <span css={styles.repeatText}>
              <FormattedMessage id="booking-info-form-modal.pyramid.title" />
            </span>
          </div>
        )}
      </div>

      {isPyramidChecked && (
        <OpponentSelect
          selectedOption={selectedOption}
          onSetSelectedOption={setSelectedOption}
        />
      )}

      {currentFacility.sports.some((sport) => sport === 'squash') &&
        isTeamChecked && (
          <TeamGameSelect
            selectedOption={teamGameOption}
            onSetSelectedOption={setTeamGameOption}
          />
        )}

      {!currentFacility.sports.some((sport) => sport === 'squash') &&
        isTeamChecked && (
          <>
            <FormInput
              type="text"
              label={formatMessage({
                id: 'booking-info-form-modal.opponent.name.label',
              })}
              id="teamOpponentName"
              name="teamOpponentName"
              Icon={User}
              value={values.teamOpponentName}
              error={errors.teamOpponentName}
              touched={touched}
              placeholder={formatMessage({
                id: 'booking-info-form-modal.opponent.name.placeholder',
              })}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormInput
              type="email"
              label={formatMessage({
                id: 'booking-info-form-modal.opponent.email.label',
              })}
              id="teamOpponentEmail"
              name="teamOpponentEmail"
              Icon={Mail}
              value={values.teamOpponentEmail}
              error={errors.teamOpponentEmail}
              touched={touched}
              placeholder={formatMessage({
                id: 'booking-info-form-modal.opponent.email.placeholder',
              })}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </>
        )}
    </>
  );
};
