import { css } from '@emotion/react';

const formStyles = css`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const inputStyles = css`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  font-size: 16px;
`;

const buttonStyles = css`
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
`;

const switchText = css`
  color: var(--color-text-4);
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;

  a {
    border: none;
    cursor: pointer;
    color: var(--color-general-primary-1);
    background-color: transparent;

    &:hover {
      color: white;
    }
  }
`;

const ctaButtons = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 240px;
`;

export const signupFormStyles = {
  formStyles,
  inputStyles,
  buttonStyles,
  switchText,
  ctaButtons,
};
