/**
 * Breakpoint values without @media tags
 */

export const breakpoints = {
  fromTny: 'screen and (min-width: 400px)',
  toTny: 'screen and (max-width: 399px)',
  fromSml: 'screen and (min-width: 480px)',
  toSml: 'screen and (max-width: 479px)',
  fromMed: 'screen and (min-width: 768px)',
  toMed: 'screen and (max-width: 767px)',
  fromLrg: 'screen and (min-width: 992px)',
  toLrg: 'screen and (max-width: 991px)',
  fromXLrg: 'screen and (min-width: 1200px)',
  toXlrg: 'screen and (max-width: 1199px)',
  fromHuge: 'screen and (min-width: 1400px)',
  toHuge: 'screen and (max-width: 1399px)',
  fromXHuge: 'screen and (min-width: 2000px)',
  toXHuge: 'screen and (max-width: 1999px)',

  from1024: 'screen and (min-width: 1024px)',
  to1024: 'screen and (max-width: 1023px)',
  from1100: 'screen and (min-width: 1100px)',
  to1100: 'screen and (max-width: 1099px)',
  from483: 'screen and (min-width: 483px)',
  to483: 'screen and (max-width: 482px)',
};
