import {
  BookingOverviewFilters,
  BookingOverviewLayout,
  BookingOverviewList,
  FILTER_RESULTS,
  useBookingOverviewListener,
} from '@modules/booking-overview';
import { SearchInput } from '@modules/search';
import { useFuseObjectSearch } from '@shared/hooks';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { styles } from './styles';

export const BookingOverview: React.FC = () => {
  const { locale } = useRouter();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOption, setSelectedOption] = useState<BookingOverviewType>(
    FILTER_RESULTS[locale][0],
  );
  const { bookings, fetching, setFetching } = useBookingOverviewListener({
    statusFilter: selectedOption,
  });
  const fuseResults = useFuseObjectSearch<Booking>(
    ['name'],
    searchTerm,
    bookings,
  );

  function handleSetFilter(filter: BookingOverviewType) {
    setSelectedOption(filter);
    setFetching(true);
  }

  return (
    <>
      <BookingOverviewLayout>
        <div css={styles.searchGroup}>
          <SearchInput
            searchTerm={searchTerm}
            onSetSearchTerm={setSearchTerm}
          />
        </div>
        <BookingOverviewFilters
          selectedOption={selectedOption}
          onSetSelectedOption={handleSetFilter}
        />
        <BookingOverviewList
          bookings={fuseResults}
          selectedOption={selectedOption}
          isLoading={fetching}
        />
      </BookingOverviewLayout>
    </>
  );
};
