import { MotionProps } from 'framer-motion';

export interface AnimationsProps {
  duration?: number;
  delay?: number;
  amount?: number;
  margin?: string;
  once?: boolean;
  ref?: React.RefObject<HTMLElement>;
  ease?: string | number[];
  y?: number;
}

const opacityInView = ({
  duration = 0.6,
  delay = 0.1,
  amount = 0.3,
  margin = '0px 0px -20% 0px',
  ease = 'easeOut',
  once = true,
}: AnimationsProps): MotionProps => ({
  initial: {
    opacity: 0,
  },
  whileInView: {
    opacity: 1,
  },
  viewport: {
    once,
    amount,
    margin,
  },
  transition: {
    duration,
    delay,
    ease,
  },
});

const yOpacityInView = ({
  duration = 0.6,
  delay = 0.1,
  amount = 0.3,
  margin = '0px 0px -20% 0px',
  ease = 'easeOut',
  once = true,
  y = 40,
}: AnimationsProps): MotionProps => ({
  initial: {
    opacity: 0,
    y,
  },
  whileInView: {
    opacity: 1,
    y: 0,
  },
  viewport: {
    once,
    amount,
    margin,
  },
  transition: {
    duration,
    delay,
    ease,
  },
});

const opacity = ({
  duration = 0.6,
  delay = 0.1,
  ease = 'easeOut',
}: AnimationsProps): MotionProps => ({
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  transition: {
    duration,
    delay,
    ease,
  },
});

export const animations = {
  opacity,
  opacityInView,
  yOpacityInView,
};
