import { useFacility } from '@modules/facility';
import { useMemo } from 'react';
import {
  useActiveCourt,
  useBookings,
  useCurrentBookedHours,
  useCurrentToDeleteBookedHours,
  useIsDestructiveMode,
  useSetBookingSlotInfo,
} from '../state';
import { abbreviateName } from '../utils';

export function useBookingSlotStates(from: Date, to: Date) {
  const facility = useFacility();
  const allBookings = useBookings();
  const activeCourt = useActiveCourt();
  const bookedHours = useCurrentBookedHours();
  const toDeleteBookedHours = useCurrentToDeleteBookedHours();
  const isDestructive = useIsDestructiveMode();
  const minSlotsInBooking = useMemo(
    () => facility?.minSlotsInBooking,
    [facility],
  );
  const hours = useMemo(
    () => (isDestructive ? toDeleteBookedHours : bookedHours),
    [bookedHours, toDeleteBookedHours, isDestructive],
  );
  const booking = allBookings?.find(
    ({ status, startTime, courtId }) =>
      status === 'accepted' &&
      startTime === from?.getTime() &&
      courtId === activeCourt?.id,
  );
  const shortenedName = useMemo(() => abbreviateName(booking?.name), [booking]);
  const isExpired = useMemo(() => from.getTime() < Date.now(), [from]);
  const isActive = useMemo(
    () =>
      Boolean(
        hours.find(
          (items) =>
            items.from <= from.getTime() &&
            items.to >= to.getTime() &&
            items.courtId === activeCourt?.id,
        ),
      ),
    [hours, activeCourt, to, from],
  );
  const price = useMemo(() => booking?.price, [booking]);
  const isInProgress = useMemo(
    () =>
      Boolean(booking) &&
      Date.now() > from.getTime() &&
      Date.now() < to.getTime(),
    [booking, from, to],
  );
  const isPaintball = useMemo(
    () => Boolean(facility?.packagedBookingData?.categories.length > 0),
    [facility],
  );
  const isReserved = useMemo(() => Boolean(booking), [booking]);
  const isRepeat = useMemo(() => booking?.isRepeat, [booking]);
  const isTournamentGame = useMemo(() => booking?.isTournament, [booking]);
  const shortenedOpponentName = useMemo(
    () => abbreviateName(booking?.opponent?.name),
    [booking],
  );
  const id = useMemo(() => booking?.id, [booking]);
  const hasFollower = useMemo(
    () =>
      isActive &&
      Boolean(
        bookedHours.find(
          (item) =>
            item.from === to.getTime() && item.courtId === activeCourt?.id,
        ),
      ),
    [bookedHours, isActive, activeCourt, to],
  );
  const hasAntecedent = useMemo(
    () =>
      isActive &&
      Boolean(
        bookedHours.find(
          (item) =>
            item.to === from.getTime() && item.courtId === activeCourt?.id,
        ),
      ),
    [bookedHours, isActive, activeCourt, from],
  );
  const setInfo = useSetBookingSlotInfo();

  function openBookingInfo() {
    setInfo(booking);
  }

  return {
    name: booking?.name,
    shortenedName,
    phoneNumber: booking?.phoneNumber,
    email: booking?.email,
    isExpired,
    isActive,
    isInProgress,
    isReserved,
    isRepeat,
    isTournamentGame,
    opponentName: booking?.opponent?.name,
    shortenedOpponentName,
    facilityName: facility?.name,
    id,
    hasFollower,
    hasAntecedent,
    price,
    minSlotsInBooking,
    isPaintball,
    openBookingInfo,
  };
}
