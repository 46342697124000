import {
  useClickOutsideElement,
  useCloseOnEscape,
  useKeyPress,
} from '@shared/hooks';
import { NewButton } from '@ui/Button';
import { motion } from 'framer-motion';
import { FC, PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useIntl } from 'react-intl';

interface Props extends PropsWithChildren {
  onClose: VoidFunction;
  handleSubmit?: () => void | Promise<void>;
  title: string;
  isLoading?: boolean;
  cancelText?: string;
  confirmText?: string;
  isDelete?: boolean;
}

export const Modal: FC<Props> = ({
  children,
  title,
  isLoading = false,
  cancelText,
  confirmText = 'Confirm',
  handleSubmit,
  onClose,
}) => {
  const { formatMessage } = useIntl();
  const ref = useCloseOnEscape<HTMLDivElement>();
  const closeRef = useClickOutsideElement<HTMLDivElement>(onClose);
  const escPressed = useKeyPress('Escape');

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    document.body.style.transition = 'all 0.3s ease';

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  useEffect(() => {
    if (escPressed) onClose();
  }, [escPressed, onClose]);

  return ReactDOM.createPortal(
    <div
      ref={ref}
      className="fixed inset-0 w-screen h-screen bg-black/50 z-50 flex items-end sm:items-center justify-center backdrop-blur-md transition-opacity duration-300 overflow-hidden"
    >
      <motion.div
        variants={{
          initial: { opacity: 0, y: '100%' },
          animate: {
            opacity: 1,
            y: 0,
            transition: { duration: 0.3, ease: [0.42, 0, 0.58, 1] },
          },
          exit: { opacity: 0, y: '100%', transition: { duration: 0.2 } },
        }}
        initial="initial"
        animate="animate"
        exit="exit"
        ref={closeRef}
        className="max-w-full h-auto sm:h-auto bg-background-2 rounded-t-xl sm:rounded-xl w-full sm:w-auto sm:max-w-[90%] py-24 px-24 sm:py-36 sm:px-32 flex flex-col gap-24 overflow-y-auto overflow-x-hidden sm:max-h-[90%] sm:min-w-[640px]"
      >
        <div className="sticky top-0 z-10">
          <div className="flex justify-between items-center gap-32">
            <h4 className="text-medium sm:text-xlarge-fluid text-white font-semibold -tracking-2">
              {title}
            </h4>
            {!cancelText && (
              <NewButton
                type="button"
                variant="icon"
                size="medium"
                onClick={onClose}
                className="group border-none !p-0"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  className="transition-all duration-200 ease-out"
                >
                  <path
                    d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z"
                    className="transition-all duration-200 ease-out fill-border-4 group-hover:fill-text-4"
                  />
                </svg>{' '}
              </NewButton>
            )}
          </div>
        </div>
        <div className="overflow-y-auto flex flex-col gap-24">{children}</div>
        {handleSubmit && (
          <div className="sticky bottom-0 py-4 flex justify-between sm:justify-start items-center gap-8">
            <NewButton
              type="button"
              variant="primary"
              size="large"
              disabled={isLoading}
              loading={isLoading}
              onClick={handleSubmit}
            >
              {confirmText}
            </NewButton>
            <NewButton
              type="button"
              onClick={onClose}
              variant="ghost"
              size="large"
            >
              {cancelText || formatMessage({ id: 'input.cancel' })}
            </NewButton>
          </div>
        )}
      </motion.div>
    </div>,
    document.body,
  );
};
