import { useFacility } from '@modules/facility';
import { useMemo } from 'react';
import { BookedHours } from '../models';
import {
  useCurrentBookedHours,
  useCurrentToDeleteBookedHours,
  useIsDestructiveMode,
  useSetCurrentBookedHours,
  useSetCurrentToDeleteBookedHours,
} from '../state';
import { mergeBookings } from '../utils';

export function useBookedHoursList() {
  const bookedHours = useCurrentBookedHours();
  const toDeleteBookedHours = useCurrentToDeleteBookedHours();
  const isDestructive = useIsDestructiveMode();
  const facility = useFacility();
  const discountConfig = useMemo(() => facility?.discountConfig, [facility]);
  const maxSlotsInBooking = useMemo(
    () => facility?.maxSlotsInBooking,
    [facility],
  );
  const minSlotsInBooking = useMemo(
    () => facility?.minSlotsInBooking || 1,
    [facility],
  );
  const isPaintball = useMemo(
    () => Boolean(facility?.packagedBookingData?.categories.length > 0),
    [facility],
  );
  const mergedBookings = useMemo(
    () =>
      Boolean(bookedHours.length)
        ? mergeBookings(
            bookedHours,
            discountConfig,
            maxSlotsInBooking,
            minSlotsInBooking,
          )
        : [],
    [bookedHours, discountConfig, maxSlotsInBooking, minSlotsInBooking],
  );
  const sortedList = useMemo(
    () =>
      isDestructive
        ? [...toDeleteBookedHours].sort((a, b) => a.from - b.from)
        : mergedBookings,
    [toDeleteBookedHours, isDestructive, mergedBookings],
  );
  const setBookedHours = useSetCurrentBookedHours();
  const setToDeleteBookedHours = useSetCurrentToDeleteBookedHours();
  const setter = isDestructive ? setToDeleteBookedHours : setBookedHours;

  /**Leaves only bookings with different time or with same time but different court */

  function onClickHandler(booking: BookedHours) {
    setter((prev) => {
      let updatedBookings: BookedHours[] = [];

      if (isDestructive) {
        updatedBookings = prev.filter(
          (booked) =>
            booked.from !== booking.from ||
            (booked.from === booking.from &&
              booked.courtId !== booking.courtId),
        );
      } else {
        updatedBookings = prev.filter(
          (booked) =>
            !(
              booking.courtId === booked.courtId &&
              booking.from <= booked.from &&
              booking.to >= booked.to
            ),
        );
      }

      if (updatedBookings.length === 0) return [];

      return updatedBookings;
    });
  }

  return {
    isPaintball,
    sortedList,
    onClickHandler,
  };
}
