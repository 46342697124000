import { useClickOutsideElement } from '@shared/hooks';
import { NewButton } from '@ui/Button';
import { animations } from 'const';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { MultiValue } from 'react-select';
import { RoleOption } from '../role-select/models';
import { styles } from './styles';

interface Props {
  email: string;
  selectedOption: RoleOption;
  selectedFacility?: Facility;
  selectedGroups?: MultiValue<GroupOption>;
  closeModal: () => void;
  handleSubmit(): Promise<void>;
}

export const AddRoleModal: React.FC<Props> = ({
  email,
  selectedOption,
  selectedFacility,
  selectedGroups,
  closeModal,
  handleSubmit,
}) => {
  const [loading, setLoading] = useState(false);
  const closeRef = useClickOutsideElement<HTMLDivElement>(closeModal);

  async function submit() {
    setLoading(true);
    await handleSubmit();
    closeModal();
    setLoading(false);
  }

  return (
    <AnimatePresence>
      <motion.div css={styles.wrapper} {...animations.opacity({})}>
        <div css={styles.base} ref={closeRef}>
          <div css={styles.columnOne}>
            <h2 css={styles.title}>
              <FormattedMessage id="add-role.modal.title" />
            </h2>
            <h3 css={styles.description}>
              <FormattedMessage id="add-role.modal.description" />
            </h3>
            <h3 css={styles.confirmDescription}>
              <FormattedMessage id="add-role.modal.confirm.description" />
            </h3>
            <div css={styles.card}>
              <span css={styles.cardEmail}>{email}</span>
              <div css={styles.cardRow}>
                <span css={styles.cardRole}>{selectedOption.label}</span>
                <span css={styles.cardFacility}>
                  {selectedFacility?.name || ''}
                </span>
              </div>
              {selectedGroups?.length &&
                selectedGroups?.map((group) => (
                  <span css={styles.cardGroups} key={group.value}>
                    {group.label}
                  </span>
                ))}
            </div>

            <div className="flex mt-24 gap-12">
              <NewButton
                type="button"
                loading={loading}
                disabled={loading}
                onClick={submit}
              >
                <FormattedMessage id="add-role.modal.confirm" />
              </NewButton>
              <NewButton variant="ghost" type="button" onClick={closeModal}>
                <FormattedMessage id="add-role.modal.cancel" />
              </NewButton>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
