import { SerializedStyles } from '@emotion/react';
import {
  getFormattedTime,
  getRepeatedCapitalizedDay,
} from '@modules/booking-new';
import { FILTER_RESULTS } from '@modules/booking-overview';
import { DateService } from '@shared/services';
import { Button } from '@ui/Button';
import { AnimatePresence, motion } from 'framer-motion';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Arrow from 'public/icons/arrow-icon-up-16.svg';
import TrashIcon from 'public/icons/trash-16-danger.svg';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { styles } from './styles';

const Repeat = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/repeat.svg'),
  {
    loading: () => null,
  },
);

const variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

interface Props {
  data: Booking;
  status: BookingOverviewType;
  isCancelModal?: boolean;
  isRepeat?: boolean;
  index?: number;
  isAccordion?: boolean;
  onSetBooking?: (booking: Booking) => void;
  openModal?: () => void;
}

export const BookingHistoryCard: React.FC<Props> = ({
  data,
  status,
  isCancelModal,
  isRepeat,
  index,
  isAccordion = true,
  onSetBooking,
  openModal,
}) => {
  const { locale } = useRouter();
  const { formatMessage } = useIntl();
  const { capitalizedDay, repeatedCapitalizedDay } = getRepeatedCapitalizedDay(
    data,
    locale,
  );
  const [isOpen, setIsOpen] = useState(isAccordion === false);

  return (
    <AnimatePresence>
      <motion.div
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
        transition={{ duration: 0.3, delay: 0.03 * index }}
        css={[
          isOpen ? styles.containerExpanded : styles.containerSmall,
          (status === FILTER_RESULTS[locale][1] ||
            status === FILTER_RESULTS[locale][2]) &&
            styles.pastContainer,
        ]}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isAccordion && (
          <motion.button
            initial={{ scaleY: 1 }}
            animate={{ scaleY: isOpen ? -1 : 1 }}
            transition={{
              duration: 0.6,
              type: 'spring',
              stiffness: 260,
              damping: 20,
            }}
            className="accordion-button"
            css={styles.accordionButton}
          >
            <Arrow viewBox="0 0 16 16" />
          </motion.button>
        )}
        <div css={styles.headerContainer}>
          <span css={styles.name}>
            {data.opponent?.name
              ? `${data.name} - ${data.opponent.name}`
              : `${data?.name}`}
            {(isRepeat || data.isRepeat) && <Repeat viewBox="0 0 16 16" />}
          </span>

          {data.isTournament && (
            <span css={styles.pyramid}>
              {data.facilityName === 'SQUASHer'
                ? 'SQUASHer'
                : formatMessage({
                    id: 'booking-slot.pyramid',
                  })}
            </span>
          )}
        </div>
        {data?.phoneNumber && <span>{data.phoneNumber}</span>}
        {data?.courtName && <span>{data.courtName}</span>}
        {data?.comment && (
          <span
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
            }}
          >
            {data.comment}
          </span>
        )}
        {isRepeat && data?.repeatDuration && (
          <span>
            {data?.repeatDuration}{' '}
            {DateService.getWeekInCroatian({
              duration: data?.repeatDuration,
              label: {
                first: formatMessage({
                  id: 'booking-summary.week.first',
                }),
                second: formatMessage({
                  id: 'booking-summary.week.second',
                }),
                third: formatMessage({
                  id: 'booking-summary.week.third',
                }),
              },
            })}
          </span>
        )}
        {(status === FILTER_RESULTS[locale][0] ||
          status == FILTER_RESULTS[locale][3]) &&
          !isCancelModal && (
            <div css={styles.bookingInfoContainer}>
              <Button
                customStyles={styles.cancel}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!onSetBooking) return;
                  onSetBooking(data);
                  openModal();
                }}
              >
                <TrashIcon />
                <FormattedMessage id="booking.history.card.cancel" />
              </Button>
            </div>
          )}
        <div css={styles.bookingInfo}>
          <span css={styles.bookingDate}>
            {isRepeat ? repeatedCapitalizedDay : capitalizedDay}
          </span>
          <span css={styles.bookingTime}>{getFormattedTime(data, locale)}</span>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};
