import { css } from '@emotion/react';
import { grid } from '@styles/grid.styes';
import { breakpoints } from '@styles/variables.styles';

const background = css`
  ${grid.basic50col};
  grid-column: 1 / span 50;
  background: var(--color-background-1);
  width: calc(100% + 40px);
  margin: 0 -20px;

  @media ${breakpoints.fromMed} {
    background-image: url('/images/gradient.png');
    background-position: center;
    background-size: cover;
    overflow: visible;
    height: -webkit-fill-available;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
  }
`;

const content = css`
  grid-column: 4 / span 44;
  display: flex;
  min-height: 450px;

  @media ${breakpoints.fromMed} {
    grid-column: 7 / span 38;
    border-radius: 20px;
    border: 1px solid var(--color-border-cardBorder);
    margin: 88px 0 120px;
  }

  @media ${breakpoints.fromHuge} {
    margin: 120px 0;
  }
`;

const leftSideWrapper = css`
  display: none;

  @media ${breakpoints.from1024} {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--color-background-1);
    border-radius: 20px 0 0 20px;
    position: relative;
  }
`;

const backToHome = css`
  position: absolute;
  top: 32px;
  left: 32px;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-3);
  transition: all 0.2s ease-out;

  svg {
    path {
      transition: all 0.2s ease-out;
    }
  }

  &:hover {
    svg {
      path {
        fill: var(--color-general-primary);
        transition: all 0.2s ease-out;
      }
    }
    color: var(--color-general-primary);
    transition: all 0.2s ease-out;
  }
`;

const smallScreenNav = css`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 20px;
  color: var(--color-text-3);
  margin: 32px 0 0;
  transition: all 0.2s ease-out;

  @media ${breakpoints.fromMed} {
    margin: 32px 64px 0;
  }

  @media ${breakpoints.from1024} {
    display: none;
  }
`;

const backButtonHover = css`
  transition: all 0.2s ease-out;

  svg {
    path {
      transition: all 0.2s ease-out;
    }
  }

  &:hover,
  &:focus,
  &:active {
    svg {
      path {
        fill: var(--color-general-primary);
        transition: all 0.2s ease-out;
      }
    }
    color: var(--color-general-primary);
    transition: all 0.2s ease-out;
  }
`;

const icon = css`
  vertical-align: bottom;
  margin-right: 12px;
`;

const logoWrapper = css`
  display: flex;
  align-items: center;
  margin: 20px 0 14px 0;
`;

const anchor = css`
  @media ${breakpoints.fromMed} {
    display: block;
    width: 255px;
    height: 49px;
  }
`;

const companyName = css`
  --color: var(--geist-foreground);
`;

const switchText = css`
  gap: 4px;
  color: var(--color-text-3);
  font-size: 16px;
  line-height: 24px;

  a {
    border: none;
    cursor: pointer;
    color: var(--color-text-6);

    &:hover {
      color: var(--color-general-primary-1);
      transition: all 0.2s ease-out;
    }
  }
`;

const rightSideWrapper = css`
  flex: 1;
  background: var(--color-background-1);
  border-radius: 20px;

  @media ${breakpoints.fromMed} {
    background: var(--color-background-2);
  }

  @media ${breakpoints.from1024} {
    border-radius: 0 20px 20px 0;
  }
`;

const form = css`
  display: flex;
  flex-direction: column;
  margin: 48px 0;

  @media ${breakpoints.fromMed} {
    margin: 48px 64px;
  }
`;

const title = css`
  color: var(--color-text-6);
  font-size: 36px;
  line-height: 40px;
  font-weight: 400;
  margin-bottom: 40px;
  letter-spacing: -0.03em;

  @media ${breakpoints.fromMed} {
    grid-column: 10 / 21;
    width: 100%;
    font-size: 40px;
  }
`;

const description = css`
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 100px;
  color: var(--color-text-6);
  text-align: center;
`;

const ctaButtons = css`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  gap: 16px;

  @media ${breakpoints.fromSml} {
    max-width: 240px;
  }
`;

export const authLayoutStyles = {
  background,
  content,
  leftSideWrapper,
  backToHome,
  smallScreenNav,
  icon,
  logoWrapper,
  anchor,
  companyName,
  switchText,
  rightSideWrapper,
  title,
  description,
  form,
  backButtonHover,
  ctaButtons,
};
