import { css } from '@emotion/react';
import { grid } from '@styles/grid.styes';

const adminMain = css`
  min-height: 100vh;
  ${grid.basic12col};
  grid-auto-rows: auto;
  width: 100%;
`;

const formStyles = css`
  display: flex;
  flex-direction: column;
`;

const inputStyles = css`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  font-size: 16px;
`;

const switchText = css`
  color: var(--color-text-4);
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 16px;

  a {
    border: none;
    cursor: pointer;
    color: var(--color-general-primary-1);
    background-color: transparent;

    &:hover {
      color: white;
    }
  }
`;

const playOffManagementMain = css`
  grid-column: 1 / 6;
`;

export const styles = {
  adminMain,
  formStyles,
  inputStyles,
  switchText,
  playOffManagementMain,
};
