import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  helpTextId: string;
}

export const InputHelptext: FC<Props> = ({ helpTextId }) => (
  <p className="mt-4 text-small-fluid text-text-5">
    <FormattedMessage id={helpTextId} />
  </p>
);
