import { css } from '@emotion/react';
import { typo } from './typo.styles';

/**
 * Root CSS
 */

export const globalStyles = css`
  :root {
    /** Colors */

    /** Text colors */

    --color-text-primary: green;
    --color-text-white: #ffffff;
    --color-text-gray-secondary: #a7a7a7;
    --color-text-gray: #737373;
    --color-text-black: #151717;
    --color-text-black-light: #2b2e25;
    --color-text-1: #151717;
    --color-text-2: #2b2e25;
    --color-text-3: #737373;
    --color-text-4: #a7a7a7;
    --color-text-5: #c7c7d1;
    --color-text-6: #ffffff;

    /** Error colors */
    --color-error: #ec3333;
    --color-error-secondary: #f37c6c;
    --color-error-tertiary: #f37c6c;
    --color-error-utility: #e2412b;
    --color-warning-utility: #fdae37;

    /** Background colors */
    --color-background-1: #16141b;
    --color-background-2: #1f1d24;
    --color-background-3: #24222a;
    --color-background-4: #2a282f;
    --color-background-5: #2f2d35;
    --color-background-light: #212023;
    --color-background-dark: #131312;
    --color-background-inactive: #5a6448;
    --color-background-secondary: #56f2df;

    /** Fill colors */
    --color-fill-white: #f4f8f7;
    --color-fill-transparent: rgba(244, 248, 247, 0.05);
    --color-fill-primary-10: rgba(172, 239, 52, 0.1);
    --color-fill-primary-40: rgba(92, 116, 48, 0.4);

    /** Icon colors */
    --color-icon-1: #93df0b;
    --color-icon-4: #61645e;
    --color-icon-5: #94988d;
    --color-icon-6: #b7bab2;
    --color-icon-7: #f4f8f7;
    --color-icon-8: #151717;
    --color-icon-light: #85b927;
    --color-icon-gray: #94988d;
    --color-icon-gray-light: #b7bab2;
    --color-icon-dark: #61645e;

    /** General colors */
    --color-general-primary: #acef34;
    --color-general-primary-1: #bff363;
    --color-general-primary-2: #d2f792;
    --color-general-primary-3: #e6fac1;
    --color-general-primary-plus1: #75af0e;
    --color-general-primary-plus2: #55800a;

    /** Border colors */
    --color-border: #c7c7d1;
    --color-border-2: #85b927;
    --color-border-3: #5a6448;
    --color-border-4: #b7bab2;
    --color-border-5: #94988d;
    --color-border-6: #61645e;
    --color-border-7: #f4f8f7;
    --color-borderLight: #d4d4d4;
    --color-borderDark: #727272;
    --color-border-cardBorder: #f8f8f814;

    /** Other colors */
    --color-black: #000000;
    --color-white: #ffffff;
    --color-primary: #4f2784;
    --color-progressBar: #d3d9d9;
    --color-secondary: #e94187;
    --color-tertiary: #f77f00;
    --color-succes: #259a51;
    --color-noted: #e9ad39;

    /** Font family */
    --font-family-primary: 'HK Grotesk', serif;
    --font-family-primaryLight: 'HK Grotesk Light', serif;
    --font-family-primaryItalic: 'HK Grotesk Italic', serif;
    --font-family-primaryMedium: 'HK Grotesk Medium', serif;
    --font-family-primarySemiBold: 'HK Grotesk Semi Bold', serif;
    --font-family-primaryBold: 'HK Grotesk Bold', serif;
    --font-family-primaryExtraBold: 'HK Grotesk Extra Bold', serif;
    --font-family-primaryBlack: 'HK Grotesk Black', serif;

    /** Font weight */
    --fontWeight-smal: 300;
    --fontWeight-regular: 400;
    --fontWeight-medium: 500;
    --fontWeight-semiBold: 600;
    --fontWeight-bold: 700;
    --fontWeight-extraBold: 800;
    --fontWeight-black: 900;

    /** Border radius */
    --radius-small: '6px';
    --radius-medium: '16px';
    --radius-large: '32px';
    --radius-rounded: '1000px';

    /** Letter spacings */
    --letter-spacing-1: '-1px';
    --letter-spacing-plus1: '1px';
    --letter-spacing-plus3: '3px';
  }

  @font-face {
    font-family: 'HK Grotesk';
    src: url('/fonts/HKGrotesk-Regular.otf') format('opentype');
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: 'HK Grotesk Light';
    font-weight: 300;
    src: url('/fonts/HKGrotesk-Light.otf') format('opentype');
  }

  @font-face {
    font-family: 'HK Grotesk Italic';
    font-weight: 300;
    src: url('/fonts/HKGrotesk-Italic.otf') format('opentype');
  }

  @font-face {
    font-family: 'HK Grotesk Medium';
    font-weight: 500;
    src: url('/fonts/HKGrotesk-Medium.otf') format('opentype');
  }

  @font-face {
    font-family: 'HK Grotesk Semi Bold';
    font-weight: 600;
    src: url('/fonts/HKGrotesk-SemiBold.otf') format('opentype');
  }

  @font-face {
    font-family: 'HK Grotesk Bold';
    font-weight: 700;
    src: url('/fonts/HKGrotesk-Bold.otf') format('opentype');
  }

  @font-face {
    font-family: 'HK Grotesk Extra Bold';
    font-weight: 800;
    src: url('/fonts/HKGrotesk-ExtraBold.otf') format('opentype');
  }

  @font-face {
    font-family: 'HK Grotesk Black';
    font-weight: 900;
    src: url('/fonts/HKGrotesk-Black.otf') format('opentype');
  }

  select {
    width: 100%;
  }

  input {
    font-family: inherit;
    -webkit-appearance: none;
    border-radius: 0;
    padding: 0;
    cursor: text;
    -webkit-appearance: none;

    &::placeholder {
      font-size: 16px;
      line-height: 24px;
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input:focus-visible {
    outline: none;
  }

  img {
    height: 100%;
    max-width: 100%;
  }

  html,
  body {
    box-sizing: border-box;
    color: var(--color-text-primary);
    min-height: 100vh;
    margin: 0 auto;
    font-family: var(--font-family-primary);
    background: var(--color-background-1);
    /* scroll-behavior: smooth;
    scroll-padding: 5rem; */
    -webkit-font-smoothing: antialiased;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  button {
    &:focus {
      outline: none;
    }
  }

  h1 {
    color: var(--color-text-secondary);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul {
    margin: 0;
    padding: 0;
  }

  ul,
  ol,
  dl {
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
    list-style: none;
  }
  h1 {
    ${typo.heading1}
  }

  h2 {
    ${typo.heading2}
  }

  h3 {
    ${typo.heading3}
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    line-height: 1.15; /* 1 */
    margin: 0; /* 2 */
  }

  input {
    &::placeholder {
      font-size: 16px;
      line-height: 24px;
    }
  }
  /**
    * Correct the inability to style clickable types in iOS and Safari.
  */
  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
  }
  /**
    * Remove the inner border and padding in Firefox.
  */
  button::-moz-focus-inner,
  [type='button']::-moz-focus-inner,
  [type='reset']::-moz-focus-inner,
  [type='submit']::-moz-focus-inner {
    border-style: none;
    padding: 0;
  }
  /**
    * Restore the focus styles unset by the previous rule.
  */
  button:-moz-focusring,
  [type='button']:-moz-focusring,
  [type='reset']:-moz-focusring,
  [type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
  }

  .gm-style .gm-style-iw-c {
    padding: 20px;
    background-color: #2a282f;
    border-radius: 12px;
    width: 320px !important;
    height: 182px !important;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);

    ::-webkit-scrollbar-track {
      background-color: #f1f1f1;
    }
    /* Set the color of the scrollbar thumb */
    ::-webkit-scrollbar-thumb {
      background-color: #888;
    }
  }

  .gm-style-iw-d {
    padding: 0 20px 20px 0;
    overflow: hidden !important;
  }

  .gm-ui-hover-effect {
    top: 14px !important;
    right: 10px !important;
  }

  .gm-ui-hover-effect > span {
    background-color: #61645e !important;
  }

  .gm-style .gm-style-iw-tc::after {
    background-color: #2a282f;
  }

  .Toastify__toast--success {
    padding: 12px 16px !important;
    border-radius: 4px !important;
    border-bottom: 2px solid var(--border-border-4, #61645e) !important;
    background: var(--general-primary-2, #55800a) !important;
  }

  .Toastify__progress-bar {
    height: 2px !important;
    border-radius: 0px 0px 0px 4px !important;
    background: var(--border-border-6, #b7bab2) !important;
  }

  .Toastify__toast--error {
    padding: 12px 16px !important;
    border-radius: 4px !important;
    border-bottom: 2px solid var(--border-border-4, #61645e) !important;
    background: var(--utility-error, #e2412b) !important;
  }

  .Toastify__toast--info {
    padding: 12px 16px !important;
    border-radius: 4px !important;
    border-bottom: 2px solid var(--border-border-3-inactive, #5a6448) !important;
    background: var(--Background-4, #2a282f) !important;
  }

  .Toastify__toast-container {
    div {
      color: var(--text-text-6, #fff) !important;

      font-family: HK Grotesk !important;
      font-size: 16px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 24px !important;
    }
  }

  .flatpickr-calendar {
    border-radius: 10px !important;
  }

  .flatpickr-innerContainer {
    background: #2a282f !important;
  }

  .flatpickr-day {
    color: #e6e1e5 !important;

    :hover {
      background: #acef34 !important;
      color: #151717 !important;
      border: 1px solid #acef34 !important;
      opacity: 0.6 !important;
    }
  }

  .flatpickr-disabled {
    opacity: 0.2;

    :hover {
      color: #e6e1e5 !important;
      background-color: transparent !important;
      border: transparent !important;
    }
  }

  .flatpickr-day.prevMonthDay,
  .flatpickr-day.nextMonthDay {
    color: #737373 !important;
  }

  .flatpickr-day.today {
    color: #acef34 !important;
    border: 1px solid #acef34 !important;
  }

  .flatpickr-day.selected {
    color: #151717 !important;
    background: #acef34 !important;
    border: 1px solid #acef34 !important;
  }

  .flatpickr-weekdays {
    background: #2a282f !important;
    color: #e6e1e5 !important;
  }

  .flatpickr-month {
    background: #2a282f !important;
    color: #e6e1e5 !important;
  }

  .flatpickr-next-month,
  .flatpickr-prev-month {
    fill: #e6e1e5 !important;

    :hover svg {
      fill: #acef34 !important;
    }
  }

  .flatpickr-weekday {
    background: #2a282f !important;
    color: #e6e1e5 !important;
  }

  .flatpickr-monthDropdown-months {
    background: #2a282f !important;
    color: #e6e1e5 !important;
  }

  .flatpickr-calendar.arrowTop:before,
  .flatpickr-calendar.arrowTop:after {
    display: none !important;
  }
`;
