import { useAuthRedirects, useFirebaseAuth } from '@modules/auth';
import { NewButton } from '@ui/Button';
import { LoadingPage } from '@ui/LoadingPage';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import LeftArrow from 'public/icons/arrow-short-left-24.svg';
import Logo from 'public/logo/playoff-logo-255.svg';
import { PropsWithChildren, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { authLayoutStyles as styles } from './authLayoutStyles';

interface Props extends PropsWithChildren {
  title: string;
  description: string;
  children: React.ReactNode;
}

export const AuthLayout: React.FC<Props> = ({ children, title }) => {
  const { loading } = useAuthRedirects();

  const { pathname } = useRouter();
  const { loading: demoLoading, handleDemoLogin } = useFirebaseAuth();

  const isSetLoginScreen = useMemo(
    () => pathname.includes('/set-login-data'),
    [pathname],
  );
  const isRegisterScreen = useMemo(
    () => pathname.includes('/register'),
    [pathname],
  );
  const isForgotPasswordScreen = useMemo(
    () => pathname.includes('/forgot-password'),
    [pathname],
  );

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div css={styles.background}>
      <div css={styles.content}>
        <div css={styles.leftSideWrapper}>
          <motion.nav
            style={{
              opacity: 0,
              y: 8,
            }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5, delay: 0.4, type: 'spring' }}
            css={styles.backToHome}
          >
            <Link
              href="/"
              target="_self"
              rel="noopener noreferrer"
              aria-label="PlayOff"
            >
              <LeftArrow css={styles.icon} />
              <FormattedMessage id="auth-layout.login.back" />
            </Link>
          </motion.nav>
          <motion.div
            style={{
              opacity: 0,
              y: 8,
            }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5, delay: 0.4, type: 'spring' }}
            css={styles.logoWrapper}
          >
            <Link
              css={styles.anchor}
              href="/"
              target="_self"
              rel="noopener noreferrer"
              aria-label="PlayOff"
            >
              <Logo css={styles.companyName} />
            </Link>
          </motion.div>
          <motion.p
            style={{
              opacity: 0,
              y: 8,
            }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5, delay: 0.4, type: 'spring' }}
            css={styles.description}
          >
            <FormattedMessage id="auth-layout.login.motto" />
          </motion.p>
          {!isSetLoginScreen &&
            !isRegisterScreen &&
            !isForgotPasswordScreen && (
              <motion.div
                css={styles.ctaButtons}
                style={{
                  opacity: 0,
                  y: 8,
                }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.5, delay: 0.4, type: 'spring' }}
              >
                <NewButton
                  disabled={loading || demoLoading}
                  type="button"
                  size="large"
                  onClick={handleDemoLogin}
                >
                  <FormattedMessage id="login-form.demo-account.label" />
                </NewButton>
              </motion.div>
            )}
          <motion.p
            style={{
              opacity: 0,
              y: 8,
            }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5, delay: 0.4, type: 'spring' }}
            css={styles.switchText}
          >
            {/* {isLoginScreen && (
              <>
                <FormattedMessage id="login-form.register.label" />
                <Link href="/register">
                  <FormattedMessage id="login-form.register.link" />
                </Link>
              </>
            )} */}
            {isRegisterScreen && (
              <>
                <FormattedMessage id="login-form.login.label" />
                <Link href="/login">
                  <FormattedMessage id="login-form.login.link" />
                </Link>
              </>
            )}
          </motion.p>
        </div>
        <div css={styles.rightSideWrapper}>
          <motion.nav
            style={{
              opacity: 0,
              y: 8,
            }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5, delay: 0.4, type: 'spring' }}
            css={styles.smallScreenNav}
          >
            <Link
              href="/"
              target="_self"
              rel="noopener noreferrer"
              aria-label="PlayOff"
              css={styles.backButtonHover}
            >
              <LeftArrow css={styles.icon} />
              <FormattedMessage id="auth-layout.login.back-smlsc" />
            </Link>
            <motion.p
              style={{
                opacity: 0,
                y: 8,
              }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.5, delay: 0.4, type: 'spring' }}
              css={styles.switchText}
            >
              {/* {isLoginScreen && (
                <>
                  <Link href="/register">
                    <FormattedMessage id="login-form.register.link" />
                  </Link>
                </>
              )} */}
              {isRegisterScreen && (
                <>
                  <Link href="/login">
                    <FormattedMessage id="login-form.login.link" />
                  </Link>
                </>
              )}
            </motion.p>
          </motion.nav>
          <motion.div
            style={{
              opacity: 0,
            }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
            css={styles.form}
          >
            <motion.h1
              style={{
                opacity: 0,
                y: 8,
              }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1.5, type: 'spring' }}
              css={styles.title}
            >
              {title}
            </motion.h1>
            {children}
          </motion.div>
        </div>
      </div>
    </div>
  );
};
