import { SerializedStyles } from '@emotion/react';
import { AdminDashboard } from '@modules/admin';
import { useFacility } from '@modules/facility';
import {
  GroupsSelect,
  RoleOption,
  RoleSelect,
  UsersManagementTable,
  useAddMember,
} from '@modules/users';
import { useModal } from '@shared/hooks';
import { NewButton } from '@ui/Button';
import { FormInput } from '@ui/FormInput';
import dynamic from 'next/dynamic';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MultiValue, SingleValue } from 'react-select';
import { toast } from 'sonner';
import { AddRoleModal } from '../add-role-modal';
import { CurrentFacilitySelect } from '../facility-select';
import { styles } from './styles';

const Mail = dynamic<{ viewBox: string; css?: SerializedStyles }>(
  () => import('public/icons/mail.svg'),
  {
    loading: () => null,
  },
);

export const UsersManagementLayout: React.FC = () => {
  const { formatMessage } = useIntl();
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const facility = useFacility();
  const [selectedOption, setSelectedOption] =
    useState<SingleValue<RoleOption> | null>(null);
  const [selectedGroups, setSelectedGroups] =
    useState<MultiValue<GroupOption> | null>(null);
  const { handleFacilityRole } = useAddMember();
  const { closeModal, isModalOpen, openModal } = useModal();

  function handleEmailChange(event: React.ChangeEvent<HTMLInputElement>) {
    setEmail(event.target.value);
  }

  function handleDisplayNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    setDisplayName(event.target.value);
  }

  async function handleModal() {
    if (!email) {
      toast.error(
        formatMessage({ id: 'users-management-form.toast.email.error' }),
      );
      return;
    }
    if (!selectedOption) {
      toast.error(
        formatMessage({ id: 'users-management-form.toast.role.error' }),
      );
      return;
    }
    if (selectedOption?.value === 'trainer' && !selectedGroups?.length) {
      toast.error(
        formatMessage({ id: 'users-management-form.toast.groups.error' }),
      );
      return;
    }

    openModal();
  }

  return (
    <AdminDashboard
      title={formatMessage({ id: 'users-management-layout.title' })}
      description={formatMessage({
        id: 'users-management-layout.description',
      })}
      containerStyles={styles.adminMain}
      mainStyles={styles.usersManagementMain}
    >
      <div css={styles.formStyles}>
        {facility?.lmsLeagueConfig && (
          <div css={styles.displayName}>
            <FormInput
              type="text"
              label={formatMessage({
                id: 'users-management-form.displayName.label',
              })}
              id="displayName"
              name="displayName"
              Icon={Mail}
              placeholder={formatMessage({
                id: 'users-management-form.displayName.placeholder',
              })}
              onChange={handleDisplayNameChange}
            />
          </div>
        )}
        <div css={styles.email}>
          <FormInput
            type="email"
            label={formatMessage({ id: 'users-management-form.email.label' })}
            id="email"
            name="email"
            isRequired
            Icon={Mail}
            placeholder={formatMessage({
              id: 'users-management-form.email.placeholder',
            })}
            onChange={handleEmailChange}
          />
        </div>
        <div css={styles.roleSelect}>
          <RoleSelect
            selectedOption={selectedOption}
            onSetSelectedOption={setSelectedOption}
          />
        </div>
        <div css={styles.facilitySelect}>
          <CurrentFacilitySelect
            facilityOption={{ label: facility?.name, value: facility?.name }}
          />
        </div>
        {selectedOption?.value === 'trainer' && (
          <div css={styles.groups}>
            <GroupsSelect
              selectedOption={selectedGroups}
              onSetSelectedOption={setSelectedGroups}
            />
          </div>
        )}
        <NewButton
          className="mt-24"
          type="button"
          onClick={() => handleModal()}
        >
          <FormattedMessage id="users-management-layout.add-user" />
        </NewButton>
      </div>
      {isModalOpen && email && selectedOption && (
        <AddRoleModal
          email={email}
          selectedFacility={facility}
          selectedOption={selectedOption}
          selectedGroups={selectedGroups}
          closeModal={closeModal}
          handleSubmit={() =>
            handleFacilityRole({
              email: email.trim(),
              selectedOption,
              displayName,
              selectedGroups,
            })
          }
        />
      )}
      <UsersManagementTable />
    </AdminDashboard>
  );
};
