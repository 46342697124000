import { AdminLayout } from '@modules/admin';
import { useUserIdLoading } from '@modules/auth';
import { PlayOffAdminLayout } from '@modules/users';
import { useRouter } from 'next/router';
import React from 'react';
import { Footer, Header, LoadingPage } from '..';
import { layoutStyles as styles } from './layout.styles';

interface Props {
  children: React.ReactNode;
}

const authRoutes = [
  '/login',
  '/forgot-password',
  '/set-login-data',
  '/register',
];

/**
 * Roles:
 * - playoff-admin
 * - admin
 * - employee
 * - trainer
 *
 * Facility types:
 * - sport
 * - private
 * - group
 */

export const Layout: React.FC<Props> = ({ children }) => {
  const { pathname } = useRouter();
  const userIdLoading = useUserIdLoading();
  const isDashboard = pathname === '/dashboard';
  const isAdmin = pathname.includes('dashboard/admin');
  const isEmployee = pathname.includes('dashboard/employee');
  const isPrivatePlayer = pathname.includes('dashboard/private-player');
  const isPlayOffAdmin = pathname.includes('dashboard/playoff-admin');
  const isAuth = authRoutes.includes(pathname);
  const isApplication = pathname.includes('/directory/application');

  if (pathname === '/' || pathname.includes('skola')) {
    return (
      <>
        <Header />
        <main css={[styles.base, isAuth && styles.authContainer]}>
          {children}
        </main>
        <Footer />
      </>
    );
  }

  if (isPlayOffAdmin) {
    return (
      <PlayOffAdminLayout>
        <>{children}</>
      </PlayOffAdminLayout>
    );
  }

  if (isAdmin || isEmployee) {
    return (
      <AdminLayout>
        <>{children}</>
      </AdminLayout>
    );
  }

  if (isAuth && userIdLoading) {
    return (
      <>
        <LoadingPage />
        {children}
      </>
    );
  }

  if (isDashboard || isPrivatePlayer) {
    return (
      <>
        <Header />
        <main css={[styles.base, isAuth && styles.authContainer]}>
          {children}
        </main>
        <Footer />
      </>
    );
  }

  if (isAuth) {
    return (
      <>
        <main css={[styles.base, isAuth && styles.authContainer]}>
          {children}
        </main>
        <Footer />
      </>
    );
  }

  if (isApplication) {
    return (
      <>
        {children}
        <Footer />
      </>
    );
  }

  return (
    <>
      <Header />
      <main css={[styles.base, isAuth && styles.authContainer]}>
        {children}
      </main>
      <Footer />
    </>
  );
};
