import { StylesConfig } from 'react-select';

export type OptionType<T = any> = {
  label: string;
  value: T;
  id?: string;
};

export const getSelectStyles = <T extends {}>(
  hasError?: boolean,
  controlMinWidth?: number,
  hasIcon?: boolean,
): StylesConfig<OptionType<T>, false> => ({
  control: (provided, state) => ({
    ...provided,
    backgroundColor: '#2f2d35',
    position: 'relative',
    borderColor: state.isFocused ? '#61645e' : hasError ? '#e2412b' : '#2f2d35',
    ':hover': {
      backgroundColor: '#2f2d35',
      borderColor: '#61645e',
      transition: 'border-color 0.2s ease-out',
    },
    boxShadow: 'none',
    cursor: 'pointer',
    color: '#ffffff',
    minHeight: '44px',
    paddingTop: '0',
    minWidth: controlMinWidth ? `${controlMinWidth}px` : '148px',
    borderRadius: '8px',
    transition: 'all 0.2s ease-out',
    ...(hasError && {
      borderColor: '#e2412b',
      transition: 'border-color 0.2s ease-out',
    }),
    // height: 'auto',
    // display: 'flex',
    // flexWrap: 'wrap',
    // alignItems: 'flex-start',
  }),
  input: (provided) => ({
    ...provided,
    color: '#ffffff',
    caretColor: '#ffffff',
    backgroundColor: 'transparent',
    '::selection': {
      backgroundColor: '#61645e',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#ffffff',
    paddingTop: '0',
    maxWidth: 'calc(100% - 16px)',
    fontSize: '16px',
    lineHeight: '24px',
    transition: 'all 0.2s ease-out',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ...(hasIcon && { paddingLeft: '32px' }),
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    borderRadius: '20px',
    padding: '5px 5px 5px 9px',
    border: '1px solid #ACEF34',
    color: '#ACEF34',
    height: '28px',
    display: 'flex',
    margin: 0,
    alignItems: 'center',
    gap: '4px',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#ACEF34',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 600,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#ACEF34',
    cursor: 'pointer',
    transition: 'all 0.2s ease-out',
    ':hover': {
      color: '#ffffff',
      backgroundColor: 'transparent',
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: '#61645e',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#ffffff',
    ':hover': {
      color: '#61645e',
      transition: 'border-color 0.2s ease-out',
    },
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: '#2f2d35',
    marginTop: '8px',
  }),
  menuList: (provided, { options }) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: '8px',
    scrollbarWidth: 'auto',
    scrollbarColor: '#61645e #2f2d35',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#61645e',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#2f2d35',
    },
    ...(options.length > 10
      ? { maxHeight: 'calc(8 * (1em + 24px))', overflowY: 'auto' }
      : {}),
  }),
  option: (provided, state) => ({
    ...provided,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    padding: '12px 16px',
    ':hover': {
      backgroundColor: '#F4F8F70D',
      transition: 'all 0.2s ease-out',
    },
    backgroundColor:
      state.isSelected || state.isFocused ? '#F4F8F70D' : 'transparent',
    color: '#ffffff',
  }),
  valueContainer: (provided) => ({
    ...provided,
    position: 'relative',
    padding: '6px 16px',
    gap: '8px',
    flexWrap: 'wrap',
    width: 'calc(100% - 37px)', // 37px is width of indicatorsContainer with divider
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    position: 'absolute',
    right: 0,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#737373',
  }),
});
