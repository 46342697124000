import { ErrorMessage } from '@hookform/error-message';
import { NewButton } from '@ui/Button';
import clsx from 'clsx';
import HidenIcon from 'public/icons/hidden-16.svg';
import ShowIcon from 'public/icons/show-16.svg';
import React, { InputHTMLAttributes, useState } from 'react';
import {
  FieldErrors,
  Path,
  RegisterOptions,
  useFormContext,
} from 'react-hook-form';
import { FormattedMessage, PrimitiveType, useIntl } from 'react-intl';
import { InputHelptext } from './InputHelptext';
import { WarningIcon } from './WarningIcon';

type Variant = 'small' | 'medium' | 'large';

export const inputBaseClasses =
  'block w-full rounded-lg bg-background-4 text-white placeholder:text-text-3 transition-all duration-200 ease-out outline-none border border-transparent focus:outline-none focus-visible:outline-none focus:ring-0';
const inputErrorClasses = '!border-error pr-40';
const inputDisabledClasses = 'disabled:cursor-not-allowed';

const labelBaseClasses = 'text-text-4 block text-label tracking-3px uppercase';
const labelErrorClasses = 'text-error';

const sizeStyles: Record<Variant, string> = {
  small: 'px-16 py-8 text-small-fluid h-36',
  medium: 'px-16 py-12 text-base-fluid h-44',
  large: 'p-16 text-medium-fluid h-56',
};

interface InputFieldProps<T> extends InputHTMLAttributes<HTMLInputElement> {
  name: Path<T>;
  labelId?: string;
  placeholderId?: string;
  isRequired?: boolean;
  helpTextId?: string;
  hasPercentage?: boolean;
  Icon?: React.ComponentType<{ viewBox?: string; className?: string }>;
  registerOptions?: RegisterOptions<T, Path<T>>;
  className?: string;
  iconAfter?: React.ReactNode;
  variant?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  values?: Record<string, PrimitiveType>;
}

export const InputField = <T,>({
  name,
  labelId,
  placeholderId,
  isRequired = false,
  helpTextId,
  hasPercentage = false,
  Icon,
  type = 'text',
  registerOptions,
  iconAfter,
  variant = 'medium',
  className,
  maxLength,
  min,
  max,
  step,
  disabled,
  values,
}: InputFieldProps<T>) => {
  const { formatMessage } = useIntl();
  const { register, formState } = useFormContext<T>();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  function togglePasswordVisibility() {
    setIsPasswordVisible((prev) => !prev);
  }

  return (
    <div className={className || ''}>
      {labelId && (
        <label
          htmlFor={name}
          className={clsx(
            labelBaseClasses,
            formState.errors?.[name]?.message && labelErrorClasses,
          )}
        >
          <FormattedMessage id={labelId} values={values} />{' '}
          {isRequired && (
            <span className="text-border-1 text-label tracking-3px">*</span>
          )}
        </label>
      )}
      <div className="relative mt-4">
        {Icon && (
          <div className="pointer-events-none absolute inset-y-0 left-0 items-center flex pl-16">
            <Icon className="w-16 h-16" />
          </div>
        )}
        <input
          {...register(name, registerOptions)}
          id={name}
          type={
            type === 'password'
              ? isPasswordVisible
                ? 'text'
                : 'password'
              : type
          }
          disabled={disabled}
          min={min}
          max={max}
          step={step}
          maxLength={maxLength}
          placeholder={
            placeholderId ? formatMessage({ id: placeholderId }) : ''
          }
          className={clsx(
            inputBaseClasses,
            formState.errors?.[name]?.message && inputErrorClasses,
            disabled && inputDisabledClasses,
            sizeStyles[variant],
            'hover:border hover:border-border-4',
            'focus:border focus:border-border-4',
            Icon && 'pl-40',
            'text-base-fluid',
          )}
        />
        {type === 'password' && (
          <div className="absolute inset-y-0 right-0 flex items-center cursor-pointer">
            <NewButton
              type="button"
              variant="ghost"
              onClick={togglePasswordVisibility}
              size="small"
              className="hover:p-16 focus:p-16 transition-all duration-200 ease-out"
            >
              {isPasswordVisible ? <ShowIcon /> : <HidenIcon />}
            </NewButton>
          </div>
        )}
        {iconAfter && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-12 text-text-5 text-base-fluid">
            {iconAfter}
          </div>
        )}
        {hasPercentage && (
          <span className="absolute inset-y-0 right-0 flex items-center pr-12 text-text-5 text-base-fluid">
            %
          </span>
        )}
        {formState.errors?.[name]?.message && type !== 'password' && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-12">
            <WarningIcon className="w-20 h-20 text-error" />
          </div>
        )}
      </div>
      <ErrorMessage
        name={name}
        errors={formState.errors as FieldErrors}
        render={({ message }) => (
          <p className="mt-4 text-tiny-fluid text-error">{message}</p>
        )}
      />
      {helpTextId && <InputHelptext helpTextId={helpTextId} />}
    </div>
  );
};
