import { firebaseStorage } from '@modules/firebase';
import { FirebaseService } from '@modules/firebase/initFirebase';
import { doc, updateDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormContext, useWatch } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { toast } from 'sonner';

interface Props {
  name: string;
  storagePath: string;
  firestorePath?: string;
  maxFileSize?: number;
  acceptedTypes?: string[];
}

export function useMultipleImageUploader({
  name,
  storagePath,
  firestorePath,
  maxFileSize = 5 * 1024 * 1024,
  acceptedTypes = ['image/*'],
}: Props) {
  const { formatMessage } = useIntl();
  const { setValue } = useFormContext();
  const existingImages = useWatch({ name });
  const [isLoading, setIsLoading] = useState(false);
  const [localFiles, setLocalFiles] = useState<File[]>([]);
  const [localPreviewUrls, setLocalPreviewUrls] = useState<string[]>([]);
  const [markedForDeletion, setMarkedForDeletion] = useState<string[]>([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedTypes?.reduce((acc, type) => ({ ...acc, [type]: [] }), {}),
    multiple: true,
    onDrop: (files) => {
      const validFiles = files?.filter((file) => file.size <= maxFileSize);
      const newPreviews = validFiles?.map((file) => URL.createObjectURL(file));
      setLocalFiles((prev) => [...prev, ...validFiles]);
      setLocalPreviewUrls((prev) => [...prev, ...newPreviews]);
    },
  });

  const handleRemove = (url: string) => {
    console.log({ existingImages });
    if (existingImages?.includes(url)) {
      console.log('Marked for deletion:', url);
      setMarkedForDeletion((prev) => [...prev, url]);
      setLocalPreviewUrls((prev) => prev.filter((preview) => preview !== url));
    } else {
      console.log('Removed:', url);
      const fileIndex = localPreviewUrls?.findIndex(
        (preview) => preview === url,
      );

      if (fileIndex !== -1) {
        setLocalFiles((prev) => prev.filter((_, index) => index !== fileIndex));
        setLocalPreviewUrls((prev) =>
          prev.filter((preview) => preview !== url),
        );
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      let updatedImages = [...existingImages];

      console.log({ markedForDeletion });
      for (const url of markedForDeletion) {
        if (firebaseStorage.isFirebaseStorageUrl(url)) {
          const storageRef = firebaseStorage.getStorageRefFromUrl(url);
          await firebaseStorage.removeStorageItem(storageRef.fullPath);
          updatedImages = updatedImages.filter((image) => image !== url);
        }
      }

      for (const file of localFiles) {
        const uploadPath = `${storagePath}/${file.name}`;
        const uploadedUrl = await firebaseStorage.addStorageItem(
          file,
          uploadPath,
        );
        updatedImages.push(uploadedUrl);
      }

      if (firestorePath) {
        await updateDoc(doc(FirebaseService.firestore, firestorePath), {
          [name]: updatedImages,
        });
      }

      setValue(name, updatedImages);
      setMarkedForDeletion([]);
      setLocalFiles([]);
      toast.success(formatMessage({ id: 'input.imageUploader.uploadSuccess' }));
    } catch (error) {
      console.error('Error during multiple image upload:', error);
      toast.error(formatMessage({ id: 'input.imageUploader.uploadError' }));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setLocalPreviewUrls([...existingImages]);
  }, [existingImages]);

  return {
    isLoading,
    localPreviewUrls,
    markedForDeletion,
    dropzoneProps: getRootProps(),
    inputProps: getInputProps(),
    handleRemove,
    handleSubmit,
  };
}
