import { useDefaultClaim } from '@modules/auth';
import { FirebaseService } from '@modules/firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { useEffect } from 'react';
import {
  useCurrentFacilityId,
  useSetFacility,
  useSetFacilityError,
} from '../state';
import { facilityFirestoreConverter } from '../utils';

export const showDebugStatuses = process.env.NODE_ENV !== 'production';

/**This hook is used to listen on current facility in all other modes */
export function useFacilityListener() {
  const defaultClaim = useDefaultClaim();
  const currentFacilityId = useCurrentFacilityId();

  const setFacility = useSetFacility();
  const setError = useSetFacilityError();

  useEffect(() => {
    if (!currentFacilityId) return;

    const document = doc(
      FirebaseService.firestore,
      'facilities',
      currentFacilityId,
    ).withConverter(facilityFirestoreConverter);

    const unsubscribe = onSnapshot(
      document,
      (snapshot) => {
        console.log('Facility snapshot', snapshot.data());
        setFacility(snapshot?.data() || null);
      },
      (error) => setError(error.message),
    );

    return () => unsubscribe();
  }, [currentFacilityId, defaultClaim, setError, setFacility]);
}
