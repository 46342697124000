import {
  deleteObject,
  getDownloadURL,
  ref,
  StorageError,
  StorageReference,
  uploadBytes,
} from 'firebase/storage';
import { FirebaseService } from '../initFirebase';

export class FirebaseStorageService {
  private readonly storage = FirebaseService.storage;

  public async addStorageItem(file: File, path: string): Promise<string> {
    try {
      const reference = ref(this.storage, path);
      const upload = await uploadBytes(reference, file);
      return await getDownloadURL(upload.ref);
    } catch (error) {
      console.error('Firebase Storage upload error:', error);
      throw new Error(
        `Failed to upload file. ${
          error instanceof StorageError ? error.message : ''
        }`,
      );
    }
  }

  public async removeStorageItem(path: string): Promise<void> {
    try {
      const storageRef = ref(this.storage, path);
      await deleteObject(storageRef);
    } catch (error) {
      console.error('Firebase Storage delete error:', error);
      throw new Error(
        `Failed to delete file. ${
          error instanceof StorageError ? error.message : ''
        }`,
      );
    }
  }

  public getStorageRefFromUrl(downloadUrl: string): StorageReference {
    const matches = downloadUrl.match(/\/o\/(.*?)\?alt=media/);
    if (!matches?.[1]) {
      throw new Error('Invalid Firebase Storage download URL format');
    }
    const decodedPath = decodeURIComponent(matches[1]);
    return ref(this.storage, decodedPath);
  }

  public isFirebaseStorageUrl(url: string): boolean {
    return url.startsWith('https://firebasestorage.googleapis.com');
  }
}

export const firebaseStorage = new FirebaseStorageService();
