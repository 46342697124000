import { ErrorMessage } from '@hookform/error-message';
import { DateService } from '@shared/services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import {
  FieldErrors,
  Path,
  PathValue,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { FormattedMessage, PrimitiveType, useIntl } from 'react-intl';
import { InputHelptext } from './InputHelptext';
import { WarningIcon } from './WarningIcon';

type Variant = 'small' | 'medium' | 'large';

const inputBaseClasses =
  'block w-full rounded-lg bg-background-4 text-white placeholder:text-text-3 transition-all duration-200 ease-out outline-none border border-transparent focus:outline-none focus-visible:outline-none focus:ring-0';
const inputErrorClasses = '!border-error pr-40';
const inputDisabledClasses = 'disabled:cursor-not-allowed';

const sizeStyles: Record<Variant, string> = {
  small: 'px-16 py-8 text-small-fluid h-36',
  medium: 'px-16 py-12 text-base-fluid h-44',
  large: 'p-16 text-medium-fluid h-56',
};

interface TimePickerFieldProps<T> {
  name: Path<T>;
  labelId?: string;
  isRequired?: boolean;
  helpTextId?: string;
  className?: string;
  variant?: Variant;
  disabled?: boolean;
  min?: number;
  max?: number;
  placeholderId?: string;
  values?: Record<string, PrimitiveType>;
  onChange?: (value: number) => void;
}

export const TimePickerField = <T,>({
  name,
  labelId,
  isRequired = false,
  helpTextId,
  variant = 'medium',
  className,
  disabled,
  placeholderId,
  min,
  max,
  values,
  onChange,
}: TimePickerFieldProps<T>) => {
  const { formatMessage } = useIntl();
  const { control, formState, setValue } = useFormContext<T>();
  const fieldValue = useWatch({ name, control });

  const currentValue = useMemo(
    () =>
      typeof fieldValue === 'number'
        ? DateService.timestampToTime(fieldValue)
        : '',
    [fieldValue],
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const timestamp = DateService.timeToTimestamp(e.target.value);
    setValue(name, timestamp as PathValue<T, Path<T>>, {
      shouldValidate: true,
    });
    onChange?.(timestamp);
  };

  return (
    <div className={className || ''}>
      {labelId && (
        <label
          htmlFor={name}
          className={clsx(
            'text-text-4 block text-label tracking-3px uppercase',
            formState.errors?.[name]?.message && 'text-error',
          )}
        >
          <FormattedMessage id={labelId} values={values} />
          {isRequired && (
            <span className="text-border-1 text-label tracking-3px">*</span>
          )}
        </label>
      )}
      <div className="relative mt-4">
        <input
          id={name}
          type="time"
          disabled={disabled}
          min={min ? DateService.timestampToTime(min) : undefined}
          max={max ? DateService.timestampToTime(max) : undefined}
          value={currentValue}
          onChange={handleChange}
          placeholder={
            placeholderId ? formatMessage({ id: placeholderId }) : ''
          }
          className={clsx(
            inputBaseClasses,
            formState.errors?.[name]?.message && inputErrorClasses,
            disabled && inputDisabledClasses,
            sizeStyles[variant],
            'min-w-[144px]',
          )}
        />
        {formState.errors?.[name]?.message && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-12">
            <WarningIcon className="w-20 h-20 text-error" />
          </div>
        )}
      </div>
      <ErrorMessage
        name={name}
        errors={formState.errors as FieldErrors}
        render={({ message }) => (
          <p className="mt-4 text-tiny-fluid text-error">{message}</p>
        )}
      />
      {helpTextId && <InputHelptext helpTextId={helpTextId} />}
    </div>
  );
};
