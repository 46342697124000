import { css } from '@emotion/react';
import { display } from '@styles/display.styles';

const wrapper = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #16141bcc;
  overflow: hidden;
`;

const base = css`
  display: flex;
  border: 1px solid #f8f8f814;
  border-radius: 8px;
  overflow: hidden;
`;

const columnOne = css`
  ${display.overflowAuto};
  height: 100%;
  padding: 36px 32px;
  min-width: 420px;
  max-width: 500px;
  background-color: var(--color-background-2);
`;

const title = css`
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 20px;
  font-weight: 400;
  color: #ffffff;
`;

const description = css`
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #c7c7d1;
`;

const card = css`
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 10px;
  border: 1px solid #f8f8f814;
  color: var(--text-text-5, #c7c7d1);
`;

const cardEmail = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

const cardRole = css`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
`;

const cardFacility = css`
  display: flex;
  padding: 8px 20px;
  align-items: flex-start;
  gap: 4px;
  border-radius: 20px;
  background: #f4f8f70d;
`;

const cardRow = css`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const confirmDescription = css`
  margin: 16px 0;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #c7c7d1;
`;

export const styles = {
  base,
  wrapper,
  columnOne,
  title,
  description,
  card,
  cardRow,
  cardEmail,
  cardRole,
  cardFacility,
  confirmDescription,
};
