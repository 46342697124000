import { NewButton } from '@ui/Button';
import { Modal } from '@ui/Modal';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useModal } from 'shared/hooks';

export const DemoAccountModal: React.FC = () => {
  const { formatMessage } = useIntl();
  const isModalHidden = JSON.parse(
    localStorage.getItem('isDemoModalHidden') || 'false',
  );
  const { isModalOpen, closeModal } = useModal(!isModalHidden);

  function closeDemoModal() {
    localStorage.setItem('isDemoModalHidden', 'true');
    closeModal();
  }

  if (!isModalOpen) return null;

  return (
    <Modal
      onClose={closeDemoModal}
      title={formatMessage({ id: 'modal.demo-account.title' })}
      confirmText={formatMessage({ id: 'input.save' })}
    >
      <div className="max-w-[496px]">
        <p className="text-[#A7A7A7] font-semibold mb-8">
          <FormattedMessage id="modal.demo-account.description1" />
        </p>
        <p className="text-[#A7A7A7] font-semibold">
          <FormattedMessage id="modal.demo-account.description2" />
        </p>
        <div className="flex flex-col gap-4 p-12 text-[#A7A7A7] font-semibold bg-[#F4F8F70D] rounded-lg my-24">
          <p>
            <FormattedMessage id="modal.demo-account.notice1" />
          </p>
          <p>
            <FormattedMessage
              id="modal.demo-account.notice2"
              values={{
                email: (
                  <a
                    href="mailto:info@theplayoff.app"
                    className="text-[#ACEF34] hover:text-[#75AF0E] transition-all duration-200 ease-out"
                  >
                    info@theplayoff.app
                  </a>
                ),
              }}
            />{' '}
          </p>
        </div>
        <NewButton
          type="button"
          variant="primary"
          size="medium"
          onClick={closeDemoModal}
        >
          <FormattedMessage id="modal.demo-account.cta" />
        </NewButton>
      </div>
    </Modal>
  );
};
