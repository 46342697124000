import { css } from '@emotion/react';
import { breakpoints } from '@styles/variables.styles';

const formStyles = css`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

const inputStyles = css`
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  font-size: 16px;
`;

const buttonStyles = css`
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
`;

const switchText = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: var(--color-text-4);
  font-size: 16px;
  line-height: 20px;
  margin-top: 24px;
  transition: all 0.2s ease-out;

  a {
    border: none;
    cursor: pointer;
    color: var(--color-general-primary-1);
    background-color: transparent;

    &:hover {
      transition: all 0.2s ease-out;
      color: var(--color-text-white);
    }
  }

  @media ${breakpoints.fromLrg} {
    margin-top: 32px;
  }
`;

const ctaButtons = css`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  gap: 16px;

  @media ${breakpoints.fromSml} {
    max-width: 240px;
  }
`;

const otherAccLoginContainer = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  margin: 20px 0;

  p {
    color: var(--color-text-3);
  }

  @media ${breakpoints.fromMed} {
    margin-bottom: 0;
  }
`;

const registerBtnContainer = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @media ${breakpoints.fromSml} {
    flex-direction: row;
  }
`;

export const loginFormStyles = {
  formStyles,
  inputStyles,
  buttonStyles,
  ctaButtons,
  switchText,
  otherAccLoginContainer,
  registerBtnContainer,
};
